import React, { Component } from 'react';
import { withNamespaces, translate, Trans } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ProjectsList from './ProjectsList';
import ProjectRouter from './ProjectRouter';

import { Switch, Route, Redirect } from 'react-router-dom';
class ProjectsViewRouter extends Component {

    constructor(state) {
        super(state);
        this.state = {

        }
    }
    render() {
        return (
            <Switch location={this.props.match.location}>
                <Route path={'/projects/:projectcode'} component={ProjectRouter} />
                <Route path={'/projects/'} component={ProjectsList} />
                <Redirect to="/projects" />
            </Switch>
        );
    }
}


export default compose(
    translate('translations'),
)(ProjectsViewRouter);
