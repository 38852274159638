import axios from 'axios';
export * from './focoosin.actions.js';

export const RETRIEVE_BILLING_INVOICES_COMPLETED = 'RETRIEVE_BILLING_INVOICES_COMPLETED';
export const RETRIEVE_BILLING_INVOICES_ERROR = 'RETRIEVE_BILLING_INVOICES_ERROR';

export const RETRIEVE_BILLING_CARDS_COMPLETED = 'RETRIEVE_BILLING_CARDS_COMPLETED';
export const RETRIEVE_BILLING_CARDS_ERROR = 'RETRIEVE_BILLING_CARDS_ERROR';

export const RETRIEVE_BILLING_SUBSCRIPTION_COMPLETED = "RETRIEVE_BILLING_SUBSCRIPTION_COMPLETED";
export const RETRIEVE_BILLING_SUBSCRIPTION_ERROR = "RETRIEVE_BILLING_SUBSCRIPTION_ERROR";

const ERROR_WITH_MESSAGE = [400, 401, 402, 403];

export function getOrganizationCreditCards(
  on_success_callback,
  on_error_callback
) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/latest/payment/cards/');

      on_success_callback(response);

    } catch (err) {

      if(!err){
        return on_error_callback({
          response: {
            status: 500
          }
        });
      }

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({
          response: {
            status: 500
          }
        });
      }
    } finally {

    }
  }
}

export function markCardAsDefault(
  card_id,
  on_success_callback,
  on_error_callback
) {

  return async (dispatch) => {
    const response = await axios('/api/latest/payment/customer/' + card_id + '/', {
      method: "PUT"
    }).then(
      (response) => {
        on_success_callback(response);
      }

    ).catch((err) => {

      if(!err){
        return on_error_callback({
          response: {
            status: 500
          }
        });
      }

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({
          response: {
            status: 500
          }
        });
      }
    })

  }
}

export function requestInvoicePayment(
  invoice_id,
  on_success_callback,
  on_error_callback
) {

  return async (dispatch) => {
    const response = await axios('/api/latest/payment/invoices/' + invoice_id + '/', {
      method: "PUT"
    }).then(
      (response) => {
        on_success_callback(response);
      }

    ).catch((err) => {

      if(!err){
        return on_error_callback({
          response: {
            status: 500
          }
        });
      }

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({
          response: {
            status: 500
          }
        });
      }
    })

  }
}

export function getInvoices(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({
          type: on_start_dispatch_type,
          payload: []
        });
      }

      const response = await axios.get('/api/latest/payment/invoices/');

      dispatch({
        type: on_success_dispatch_type,
        payload: response.data
      });

    } catch (error) {
      if (error !== null) {
        dispatch({
          type: on_error_dispatch_type,
          payload: error
        });
      }
    } finally {

    }
  }
}

export function getCards(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({
          type: on_start_dispatch_type,
          payload: []
        });
      }

      const response = await axios.get('/api/latest/payment/cards/');

      dispatch({
        type: on_success_dispatch_type,
        payload: response.data
      });

    } catch (error) {
      if (error !== null) {
        dispatch({
          type: on_error_dispatch_type,
          payload: error
        });
      }
    } finally {

    }
  }
}

export function getSubscription(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({
          type: on_start_dispatch_type,
          payload: []
        });
      }

      const response = await axios.get('/api/latest/payment/subscription/');

      dispatch({
        type: on_success_dispatch_type,
        payload: response.data
      });

    } catch (error) {
      if (error !== null) {
        dispatch({
          type: on_error_dispatch_type,
          payload: error
        });
      }
    } finally {

    }
  }
}

export function addNewCreditCard(
  cardToken,
  on_success_callback,
  on_error_callback
) {
  return async (dispatch) => {
    const response = await axios('/api/latest/payment/cards/', {
      method: "POST",
      data: cardToken,
      "content-type": 'json'
    }).then(
      (response) => {

        on_success_callback(response);
      }

    ).catch((err) => {
      if(!err){
        return on_error_callback({
          response: {
            status: 500
          }
        });
      }

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({
          response: {
            status: 500
          }
        });
      }
    })
  }
}

export function deleteCreditCard (
  cardId,
  on_success_callback,
  on_error_callback
) {
  return async (dispatch) => {
    const response = await axios('/api/latest/payment/cards/'+cardId+'/',{
      method: 'DELETE'
    }).then((response) => {
      on_success_callback(response);
    }).catch((err) => {
      if(!err){
        return on_error_callback({
          response: {
            status: 500
          }
        });
      }

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({
          response: {
            status: 500
          }
        });
      }

    });
  }
}

