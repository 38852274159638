import React, { Component } from 'react';

import {
    getProfileImageUrl,
} from '../../store/actions/focoosin.actions.js';
import ReactTooltip from 'react-tooltip'
import UserAvatar from 'react-user-avatar';
import './reactUserAvatar.css';

class ProfilePicture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        }
    }

    componentDidMount(){
        if(this.props.isInitials){
            this.props.isInitials(this.state.error ? true : false);
        }
    }

    componentDidUpdate(prevProps) {

        if (prevProps.profileImageTimeStamp != this.props.profileImageTimeStamp) {
            this.setState({ error: false });

            if(this.props.isInitials){
                this.props.isInitials(this.state.error ? true : false);
            }

        }
    }

    render() {
        const user_key = !!this.props.user_key ? this.props.user_key : null;
        const display_user_name = this.props.display_user_name;
        var src = this.props.src ? this.props.src : getProfileImageUrl(user_key);
        const profileImageTimeStamp = this.props.profileImageTimeStamp == null ? (new Date()).getSeconds() : this.props.profileImageTimeStamp;
        src = `${src}?timeStamp=${profileImageTimeStamp}`;
        // const thumbSize = this.props.size != undefined && this.props.size == 'small' ? 'thumb24':'thumb48';
        const thumbSize = this.props.size != undefined && this.props.size == 'small' ? '25px' :
            this.props.size == 'big' ? '200px' : '50px';        

        return (
            <small data-tip data-for={user_key} id={profileImageTimeStamp}>
                {this.state.error && display_user_name && <UserAvatar size={thumbSize} name={display_user_name} style={{fontSize: this.props.size == "big" ? 50 : '',}}/>}
                {!this.state.error && <img className={`rounded-circle ${thumbSize}`} src={src} alt={display_user_name} width={thumbSize} height={thumbSize} style={{marginTop:'-10px',marginBottom:'-10px'}} onError={() => { this.setState({ error: true }, () => {
                    if(this.props.isInitials){
                        this.props.isInitials(this.state.error ? true : false);
                    }
                }) }} />}
            </small>
        )
    }
}

export default ProfilePicture;