import {
  REQUEST_TEAMS_COMPLETED,
  RQST_USERS_AVAILALBE_FOR_TEAM_DONE,
  RQST_CREATE_TEAM_KEY_DONE

} from '../actions/focoosin.teams.actions.js';
import { truncateSync } from 'fs';

var moment = require('moment-timezone');

const INITIAL_STATE = {
  teamList: null,
  usersAvailableForTeam: [],
  newTeamKey: ''
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {

    case REQUEST_TEAMS_COMPLETED:
      if (!action.error) {
        return { ...state, teamList: action.payload };
      }
      return state;

    case RQST_USERS_AVAILALBE_FOR_TEAM_DONE:
      if (!action.error) {
        return { ...state, usersAvailableForTeam: action.payload };
      }
      return { ...state, usersAvailableForTeam: [] };

    case RQST_CREATE_TEAM_KEY_DONE:
      return {
        ...state, newTeamKey: action.payload.project_code
      }

    default:
      return state;
  }
}
