import {
  RETRIEVE_ACCOUNT_USERS_COMPLETED
} from '../actions/focoosin.actions.js';

const INITIAL_STATE = {
  accountUserList: []
}

export default function (state = INITIAL_STATE, action) {

  switch (action.type) {
    case RETRIEVE_ACCOUNT_USERS_COMPLETED:
      return {
        ...state,
        accountUserList: action.payload
      }
    default:
      return state;
  }


}


