/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.2.2
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: "https://efe65c302e8b4e339bc7f83ef61b67bd@sentry.io/1446156"
});


ReactGA.initialize('UA-66393632-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }


  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId })
    });
  }

  render() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    if (this.state.error) {
      //render fallback UI
      return (
        <a onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</a>
      );
    } else {
      //when there's not an error, render children untouched
      return (
        <BrowserRouter basename={basename}>
          <Routes />
        </BrowserRouter>
      );
    }
  }
}

export default App;
