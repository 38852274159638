import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers/reducers';
import middlewares from './middlewares/middlewares'

import { updateTheme } from './middlewares/themes.middleware.js';

import { persistedState, saveState } from './persisted.store.js';

// Logger with default options
import logger from 'redux-logger'


function configureStore() {

    if (process.env.NODE_ENV === `development`) {
        const { logger } = require(`redux-logger`);
       
        middlewares.push(logger);
      }
  
      
    const store = createStore(
        reducers,
       // persistedState, // second argument overrides the initial state //Alvaro: Commented due we dont one for now, save the state in browser
        applyMiddleware(
            ...middlewares
        )
    );

    // add a listener that will be invoked on any state change
    store.subscribe(() => {
        saveState(store.getState());
    });

    // Update the initial theme
    updateTheme(store.getState())

    return store;

}

const store = configureStore();
export default store;