import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import {compose} from 'redux';
import { REQUEST_PROJECT_EVENTS_SUCCESS, requestProjectEvents } from '../../store/actions/focoosin.projects.actions.js';
import * as moment from 'moment';

class ProjectEventList extends Component {

  constructor(state) {
    super(state);
    this.state = {
      eventsLimit: 10
    };
    this.loadMoreEvents = this.loadMoreEvents.bind(this);
  }

  componentDidMount() {
    this.pullProjectEvents();
  }

  loadMoreEvents(){
    const currentLimit = this.state.eventsLimit;
    this.setState({eventsLimit:currentLimit + 10 });
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("<ProjectEventList> ProjectId", this.props.projectId);
    if (prevProps.projectId != this.props.projectId || prevState.eventsLimit != this.state.eventsLimit ) {
      this.pullProjectEvents();
    }
  }

  pullProjectEvents() {
    if (this.props.projectId) {
      this.props.requestProjectEvents(
        this.props.projectId,
        { limit: this.state.eventsLimit, offset:0 },
        REQUEST_PROJECT_EVENTS_SUCCESS,
        null,
        null
      )
    }

  }
  renderRow(eventData){

    let eventDate = moment(eventData.date).fromNow();
    let actorName = eventData.actor_text;
    let paragraph = <p className="mb-1 text-sm">No content.</p>;
    let eventIcon = null;
    switch (eventData.event_type) {
      case "PRJ.CREATED":
      paragraph = <p className="mb-1 text-sm">Has create the project</p>;
      eventIcon = "fa-fw fa icon-plus mr-2";
      break
      case "PRJ.UPDATED":
        switch (eventData.update_code) {
          case "FUC_MEMB_UPDATED":
            paragraph = <p className="mb-1 text-sm">Has modified project memebers.</p>;
            eventIcon = "fa-fw fa fa-users-cog mr-2";
            break
            case "FUC_DUE_DATE_CHANGED":
              paragraph = <p className="mb-1 text-sm">Has set a new due date: {
                moment(eventData.update_values.old_value).format(this.props.userProfileSettings.dateFormat)} &rarr; { moment(eventData.update_values.new_value).format(this.props.userProfileSettings.dateFormat) } </p>;
              eventIcon = "fa-fw fa fa-calendar-alt mr-2";
              break
            case "FUC_STATUS_UPDATED":
            paragraph = <p className="mb-1 text-sm">Has update status: {
              eventData.update_values.old_value} &rarr; { eventData.update_values.new_value } </p>;
            eventIcon = "fa-fw fa icon-folder-alt mr-2";
            break
            default:
          {
          }
        }
      break;
      case "PRJCMT.CREATED":
      paragraph = <p className="mb-1 text-sm">Has added a comment: {eventData.object_text}</p>;
      eventIcon = "fa-fw fa fa-comment-alt mr-2";
      break;
      case "TSK.FPLAY_ACT":
      paragraph = <p className="mb-1 text-sm">Has started to work on <Link to={ '/tasks/' + eventData.object_id }>{ eventData.object_text }</Link>.</p>;
      eventIcon = "fa-fw fa fa-play-circle mr-2";
      break;
      default:
      return null;
    }

    return (
      <div className="list-group-item list-group-item-action">
        <div className="media">
          {/* <img className="align-self-start mx-2 circle thumb32" src="img/user/02.jpg" alt="Avatar" /> */}
          <em className={eventIcon}></em>
          <div className="media-body text-truncate">
            <p className="mb-1">
              <strong className="text-primary">
                {/* <span className="circle bg-success circle-lg text-left"></span> */}
                <span>{ actorName }</span>
              </strong>
            </p>
            { paragraph }
          </div>
          <span className="badge badge-purple float-right">{eventDate}</span>
        </div>
      </div>
    )

  }
  render() {

    return (
      <div>
        <div className="list-group mb0">
          { this.props.activeProjectEventsList.map( eD => this.renderRow(eD))}
          <div className="list-group-item list-group-item-action">
            <div className="media">
              <div className="media-body text-truncate">
                <a onClick={this.loadMoreEvents}><em className="fa-fw fa fa-arrow-circle-down mr-2"></em>Load more</a>
              </div>
            </div>
          </div>
        </div>
        {/* END list group */}

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    activeProjectEventsList:[], //state.projectsReducer.activeProjectEventsList,
    userProfileSettings: state.sessionUserSettings,
  }
}

export default compose(
  connect(mapStateToProps, {requestProjectEvents})
)(ProjectEventList);