import {

    RETRIEVE_ROLES_COMPLETED,
    RETRIEVE_ROLES_ERROR

} from '../actions/actions';

const initialState = {
    roles: []
}

const fiRolesSettings = (state = initialState, action) => {

    switch (action.type) {

        case RETRIEVE_ROLES_COMPLETED:
            if (action.error) return { ...state, cards: null};
            else return {...state, roles: action.payload};

        case RETRIEVE_ROLES_ERROR:
            return { ...state, roles: []};

        default:
            return state;
    }
}

export default fiRolesSettings;
