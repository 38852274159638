import React, { Component } from 'react';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import { Card, CardBody, CardHeader } from 'reactstrap';

import dragula from 'react-dragula';
import 'react-dragula/dist/dragula.css';

class Kanban extends Component {
    state = {
        pending: ['Card#1', 'Card#2', 'Card#3'],
        inprogress: ['Card #4', 'Card#5'],
        done: ['Card #6']
    };

    componentDidMount() {
        this.slotPending = document.querySelector('#pending');
        this.slotInprogress = document.querySelector('#inprogress');
        this.slotDone = document.querySelector('#done');
        dragula([this.slotPending, this.slotInprogress, this.slotDone]).on('drop', this.onDrop);
    }

    onDrop = (el, target, source, sibling) => {
        this.setState({
            pending: [].slice
                .apply(this.slotPending.querySelectorAll('.card-header'))
                .map(h => h.innerHTML),
            inprogress: [].slice
                .apply(this.slotInprogress.querySelectorAll('.card-header'))
                .map(h => h.innerHTML),
            done: [].slice
                .apply(this.slotDone.querySelectorAll('.card-header'))
                .map(h => h.innerHTML)
        });

        console.table(this.state);
    };

    render() {
        return (
            <div>
                <div className="d-flex">
                    <div className="mr-auto mb-3 mt-2">
                        <button className="btn btn-info btm-sm" type="button">
                            <em className="ion-plus mr-2" />
                            Add Task
                        </button>
                    </div>
                    <div className="ml-auto wd-md text-right mt-2">
                        <small className="text-muted lh1">* Kanban view powered by Dragula</small>
                    </div>
                </div>
                <form className="mb-3">
                    <div className="form-row align-items-center">
                        <div className="col-8">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Filter tasks..."
                            />
                        </div>
                        <div className="col-4">
                            <select className="custom-select fw">
                                <option value="">Select state</option>
                                <option value="">Pending</option>
                                <option value="">In Progress</option>
                                <option value="">Done</option>
                            </select>
                        </div>
                    </div>
                </form>
                <div className="row">
                    <div className="col-lg-4">
                        <h3>Pending</h3>
                        <div id="pending" style={{ minHeight: '50px' }}>
                            <Card>
                                <CardHeader>Card #1</CardHeader>
                                <CardBody>
                                    Sed vel lacinia ante. Ut turpis urna, tristique sed adipiscing
                                    nec, luctus quis leo.
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>Card #2</CardHeader>
                                <CardBody>
                                    Sed vel lacinia ante. Ut turpis urna, tristique sed adipiscing
                                    nec, luctus quis leo.
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>Card #3</CardHeader>
                                <CardBody>
                                    Sed vel lacinia ante. Ut turpis urna, tristique sed adipiscing
                                    nec, luctus quis leo.
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <h3>In Progress</h3>
                        <div id="inprogress" style={{ minHeight: '50px' }}>
                            <Card>
                                <CardHeader>Card #4</CardHeader>
                                <CardBody>
                                    Sed vel lacinia ante. Ut turpis urna, tristique sed adipiscing
                                    nec, luctus quis leo.
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>Card #5</CardHeader>
                                <CardBody>
                                    Sed vel lacinia ante. Ut turpis urna, tristique sed adipiscing
                                    nec, luctus quis leo.
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <h3>Done</h3>
                        <div id="done" style={{ minHeight: '50px' }}>
                            <Card>
                                <CardHeader>Card #6</CardHeader>
                                <CardBody>
                                    Sed vel lacinia ante. Ut turpis urna, tristique sed adipiscing
                                    nec, luctus quis leo.
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>Card #7</CardHeader>
                                <CardBody>
                                    Sed vel lacinia ante. Ut turpis urna, tristique sed adipiscing
                                    nec, luctus quis leo.
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Kanban;
