import axios from 'axios';
import store from '../store';

export * from './focoosin.actions.js';

// Working On
export const REQUEST_ACTIVE_TASK = 'REQUEST_ACTIVE_TASK';
export const REQUEST_DASHBOARD_WORKING_ON = 'REQUEST_DASHBOARD_WORKING_ON';
export const REQUEST_MYTASKS_SEARCH = 'REQUEST_MYTASKS_SEARCH';

const ERROR_WITH_MESSAGE = [400, 403];
