import React, { Component } from 'react';
import { translate, withNamespaces, Trans } from 'react-i18next';
import Header from './Header'
import Sidebar from './Sidebar'
import Offsidebar from './Offsidebar'
import Footer from './Footer'
import { Alert, Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import CookieConsent from "react-cookie-consent";

class Base extends Component {
    constructor(props){
        super(props);
        this.state = {
            fadeIn: false
        };
    }

    componentDidMount = () => {
        this.forward = setTimeout(() => {
            this.setState({
                fadeIn: true
            });
        }, 1500);
    }

    render(){

        const props = this.props;

        return(
            <div className="wrapper">
                <Header />
        
                <Sidebar />
        
                <Offsidebar />
        
                <section className="section-container">
                { props.globalConnectionError !== '' && <div className="animated fadeInDown"><br/><Alert color="warning"><em className={"fa mr-2 fas fa-wifi"}></em> <Trans i18nKey='common.CONNECTIONERROR'></Trans> <Button outline className="mb-1" size="xs" color="primary" type="button" onClick={() => window.location.reload()} ><em className={"fa mr-2 fas fa-sync-alt"}></em> <Trans i18nKey='common.REFRESHPAGE'></Trans></Button> [ {props.globalConnectionError} ]</Alert></div> }
                { ! props.globalAuthenticated && <div className="animated fadeInDown"><br/><Alert color="warning"><em className={"fa mr-2 fas fa-user-shield"}></em> <Trans i18nKey='common.UNAUTHENTICATED'></Trans></Alert></div> }
                
                    { props.children }
                </section>
        
                <Footer />
        
                <CookieConsent
                    location="bottom"
                    buttonText={props.t("dashboard.cookies.CONSENTBUTTON")}
                    cookieName="FocoosinConsentCookie"
                    style={{background: "#43c0e8", fontSize: "16px"}}
                    buttonStyle={{ background: "#fe9335", color: "#fff", fontSize: "12px", borderRadius: "12px" }}
                    expires={150}
                >
                    <em style={{fontSize: 14}} className={"fa fas fa-cookie"}></em>&nbsp;&nbsp;
                    <Trans i18nKey='dashboard.cookies.CONSENTTITLE'></Trans>{" "}&nbsp;&nbsp;
                    <span style={{ fontSize: "12px" }}><Trans i18nKey='dashboard.cookies.CONSENTDESCRIPTION'></Trans></span>
                </CookieConsent>
            </div>
        )

    }
}



Base.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => (
    {
        settings: state.settings,
        languageCode: state.sessionUserSettings.languageCode,
        globalConnectionError: state.sessionUserSettings.globalConnectionError,
        globalAuthenticated: state.sessionUserSettings.globalAuthenticated
    })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })


export default compose(
    withRouter,
    translate('translations'),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Base);
