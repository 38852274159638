import {
  REQUEST_ACTIVE_TASK,
  REQUEST_DASHBOARD_WORKING_ON,
  REQUEST_MYTASKS_SEARCH
} from '../actions/focoosin.tasks.actions.js';
import { truncateSync } from 'fs';

var moment = require('moment-timezone');

const INITIAL_STATE = {
  activeTask:{},
  mytasks:[],
  teamWorkingOn:[]
};

export default function(state = INITIAL_STATE, action){
  switch(action.type) {
        case REQUEST_ACTIVE_TASK:
            if(!action.error && action.payload.length > 0){
                return {...state, activeTask:action.payload[0]};
            }
            return {...state, activeTask:{}};
        case REQUEST_DASHBOARD_WORKING_ON:
            return {...state, teamWorkingOn:action.payload}
        case REQUEST_MYTASKS_SEARCH:
            return {...state, mytasks:action.payload}
      default:
        return state;
  }
}
