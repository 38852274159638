import {

    RETRIEVE_ORG_USER_WORKING_TIME_COMPLETED,
    RETRIEVE_ORG_USER_WORKING_TIME_ERROR

} from '../actions/actions';

const initialState = {
    profileData: {}
}

const fiOrganizationUserWorkingTime = (state = initialState, action) => {

    switch (action.type) {

        case RETRIEVE_ORG_USER_WORKING_TIME_COMPLETED:
            if (action.error) return { ...state, cards: null};
            else return {...state, profileData: action.payload};

        case RETRIEVE_ORG_USER_WORKING_TIME_ERROR:
            return { ...state, profileData: action.payload};

        default:
            return state;
    }
}

export default fiOrganizationUserWorkingTime;