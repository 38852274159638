import React, { Component } from 'react';
import { translate, Trans } from 'react-i18next';


import {
    getOrganizationLogoUrl,
} from '../../store/actions/focoosin.actions.js';
import ReactTooltip from 'react-tooltip'
import UserAvatar from 'react-user-avatar';
import './reactUserAvatar.css';

class OrganizationLogo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        }
    }

    componentDidMount(){
        if(this.props.isInitials){
            this.props.isInitials(this.state.error ? true : false);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.OrganizationLogoTimeStamp != this.props.OrganizationLogoTimeStamp) {
            this.setState({ error: false });

            if(this.props.isInitials){
                this.props.isInitials(this.state.error ? true : false);
            }

        }
        
    }

    render() {

        if(this.props.isHeader){

            const user_key = this.props.user_key;
        const display_organization_name = this.props.display_organization_name;
        var src = this.props.src ? this.props.src : getOrganizationLogoUrl();
        const OrganizationLogoTimeStamp = this.props.OrganizationLogoTimeStamp == null ? (new Date()).getTime() : this.props.OrganizationLogoTimeStamp;
        src = `${src}?timeStamp=${OrganizationLogoTimeStamp}`;

        const thumbSize = this.props.size != undefined && this.props.size == 'small' ? '25px' :
            this.props.size == 'big' ? '200px' : '50px';        

        return (
            <div>
                {this.state.error ?
                    this.state.collapsed ? 
                        <img className="img-fluid" src="fiimg/focoosin_logo_single.png" alt="Focoosin Logo" /> : 
                        <img className="img-fluid" src="fiimg/focoosin_txt_logo_header.png" alt="Focoosin Logo" />
                    :
                    <small data-tip data-for={user_key} id={OrganizationLogoTimeStamp}>
                    {!this.state.error && <img className={`rounded-circle ${thumbSize}`} src={src} alt={display_organization_name} width={thumbSize} height={thumbSize} onError={() => { this.setState({ error: true }, () => {
                        if(this.props.isInitials){
                            this.props.isInitials(this.state.error ? true : false);
                        }
                    }) }} />}
                    </small>
                }
            </div>
        )

        }else{

            const user_key = this.props.user_key;
            const display_organization_name = this.props.display_organization_name;
            var src = this.props.src ? this.props.src : getOrganizationLogoUrl();
            const OrganizationLogoTimeStamp = this.props.OrganizationLogoTimeStamp == null ? (new Date()).getTime() : this.props.OrganizationLogoTimeStamp;
            src = `${src}?timeStamp=${OrganizationLogoTimeStamp}`;

            const thumbSize = this.props.size != undefined && this.props.size == 'small' ? '25px' :
                this.props.size == 'big' ? '200px' : '50px';        
    
            return (
                <div>
                    {this.state.error ?
                        <label><Trans i18nKey='settings.organization.logo.NOLOGO'></Trans></label> : 
                        <small data-tip data-for={user_key} id={OrganizationLogoTimeStamp}>
                        {!this.state.error && <img className={`rounded-circle ${thumbSize}`} src={src} alt={display_organization_name} width={thumbSize} height={thumbSize} onError={() => { this.setState({ error: true }, () => {
                            if(this.props.isInitials){
                                this.props.isInitials(this.state.error ? true : false);
                            }
                        }) }} />}
                        </small>
                    }
                </div>
            )

        }

       
    }
}

export default OrganizationLogo;