import axios from 'axios';
import store from '../store';

export * from './focoosin.actions.js';

// Teams
export const POST_CREATE_TEAM_DONE = 'POST_CREATE_TEAM_DONE';
export const RQST_USERS_AVAILALBE_FOR_TEAM_DONE = 'RQST_USERS_AVAILALBE_FOR_TEAM_DONE';
export const RQST_CREATE_TEAM_KEY_DONE = 'RQST_CREATE_TEAM_KEY_DONE';
export const RQST_UPDATE_TEAM_COMPLETED = 'RQST_UPDATE_TEAM_COMPLETED';
export const RQST_UPDATE_TEAM_FAILED = 'RQST_UPDATE_TEAM_FAILED';
export const REQUEST_TEAMS_COMPLETED = 'REQUEST_TEAMS_COMPLETED';

const ERROR_WITH_MESSAGE = [400, 403];

export function updateTeam(team_key, payload, on_success_callback, on_error_callback) {
  return async (dispatch) => {

    const response = await axios(`/api/latest/teams/${team_key}/`, {
      method: "put",
      data: payload
    }).then(
      (response) => {
        dispatch({ type: 'a', payload: response.data })
        if (on_success_callback !== undefined) return on_success_callback(response)
      }
    ).catch((err) => {
      console.log(err.response.status);

      if (ERROR_WITH_MESSAGE.includes(err.response.status)) {
        on_error_callback({
          response: JSON.parse(err.request.response)
        });
      }
      else {
        on_error_callback({
          response: { user_message: 'Error' }
        });
      }
    })
  }
}


export function deleteTeam(team_key, on_success_callback, on_error_callback) {
  return async (dispatch) => {

    const response = await axios(`/api/latest/teams/${team_key}/`, {
      method: "delete"
    }).then(
      (response) => {
        dispatch({ type: 'a', payload: response.data })
        if (on_success_callback !== undefined) return on_success_callback(response)
      }
    ).catch((err) => {

      if (ERROR_WITH_MESSAGE.includes(err.response.status)) {
        on_error_callback({
          response: JSON.parse(err.request.response)
        });
      }
      else {
        on_error_callback({
          response: { user_message: 'Error' }
        });
      }
    })
  }
}



export function generateTeamKey(teamName, on_success_dispatch, on_success_callback, on_error_callback) {
  return async (dispatch) => {
    const response = await axios('/api/latest/project-key-generator/',
      {
        method: "post",
        data: { project_name: teamName },
      }).then(
        (response) => {
          dispatch({ type: on_success_dispatch, payload: response.data });
          on_success_callback(response);
        }
      ).catch(
        (error) => {
          on_error_callback(error)
        }
      );

  }
}

export function requestTeams(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null,
  on_success_callback = null
) {
  return async (dispatch) => {
    if (on_start_dispatch_type) {
      dispatch({ type: on_start_dispatch_type, payload: [] });
    }
    const response = await axios.get('/api/latest/teams/', {
      params
    }).then(
      (response) => {
        dispatch({ type: on_success_dispatch_type, payload: response.data });
        if (on_success_callback !== null) {
          on_success_callback(response);
        }
      }
    ).catch(e => {
      console.log('Error on requestTeams action', e);
      if (on_error_dispatch_type != null)
        dispatch({ type: on_error_dispatch_type, payload: null });
    })
  }
}

export function removeTeamAsFavorite(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null,
  on_success_callback = null,
  on_error_callback = null
) {
  return updateTeamAsFavorite(
    '/api/latest/remove-favorite-team/',
    params,
    on_success_dispatch_type,
    on_start_dispatch_type,
    on_error_dispatch_type,
    on_success_callback,
    on_error_callback
  )
}

export function addTeamAsFavorite(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null,
  on_success_callback = null,
  on_error_callback = null
) {
  return updateTeamAsFavorite(
    '/api/latest/favorite-team/',
    params,
    on_success_dispatch_type,
    on_start_dispatch_type,
    on_error_dispatch_type,
    on_success_callback,
    on_error_callback
  )
}

export function updateTeamAsFavorite(
  url,
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null,
  on_success_callback = null,
  on_error_callback = null
) {
  return async (dispatch) => {
    if (on_start_dispatch_type) {
      dispatch({ type: on_start_dispatch_type, payload: [] });
    }
    const response = await axios(url, {
      method: "post",
      data: params,
    }).then(
      (response) => {
        dispatch({ type: on_success_dispatch_type, payload: response.data });
        if (on_success_callback !== null) {
          on_success_callback(response);
        }
      }
    ).catch(e => {
      console.log('Error on requestTeams action', e);
      if (on_error_dispatch_type != null)
        dispatch({ type: on_error_dispatch_type, payload: null });
      if (on_error_callback != null) {
        on_error_callback(e.request.response);
      }
    })
  }
}