import React, { Component } from 'react';
import { withNamespaces, translate, Trans } from 'react-i18next';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
/* loader component for Suspense*/
import PageLoader from '../../components/Common/PageLoader';

import ProjectsListEmpty from './ProjectsListEmpty';
import ProjectsViewRouter from './ProjectsViewRouter';

import { compose } from 'redux';
import { connect } from 'react-redux';
import * as moment from 'moment';
import {
  requestProjects,
  REQUEST_PROJECTS_COMPLETED
} from '../../store/actions/focoosin.projects.actions.js';

// CSS Loaders
import 'loaders.css/loaders.css';
import 'spinkit/css/spinkit.css';


class ProjectsWrapper extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.userProfileSettings.noMonitoringDataReceived === null && this.props.userProfileSettings.user_key !== prevProps.userProfileSettings.user_key) {
      this.retrieveSessionUserStatus();
    }
  }

  retrieveSessionUserStatus() {

    let args = {
    }

    this.props.requestProjects(
      args,
      REQUEST_PROJECTS_COMPLETED,
      null,
      null
    )
  }

  componentDidMount() {
    this.retrieveSessionUserStatus();
  }

  render() {

    return (
      <div>
        {this.props.projectsReducer.projectsList === null && <PageLoader />}
        {this.props.projectsReducer.projectsList !== null &&  this.props.projectsReducer.projectsList.length == 0 && <ProjectsListEmpty />}
        {this.props.projectsReducer.projectsList !== null &&  this.props.projectsReducer.projectsList.length > 0 && <Route path={'/projects'} component={ProjectsViewRouter} />}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    userProfileSettings: state.sessionUserSettings,
    projectsReducer: state.projectsReducer
  }
}

export default compose(
  translate('translations'),
  connect(mapStateToProps, { requestProjects })
)(ProjectsWrapper);
