import {
    RETRIEVE_ORG_NOTIFICATIONS_COMPLETED,
    RETRIEVE_ORG_NOTIFICATIONS_ERROR
} from '../actions/actions';

const initialState = {
    "members_can_edit": null,
    "monthly_news_letter": null,

    errorMessage: null
 }

const fiOrganizationNotifications = (state = initialState, action) => {
    
    switch (action.type) {
        case RETRIEVE_ORG_NOTIFICATIONS_COMPLETED:
            if (action.error) return { ...state };
            else
                return {
                    ...state,
                    "members_can_edit": action.payload.members_can_edit,
                    "monthly_news_letter": action.payload.monthly_news_letter
                };


        case RETRIEVE_ORG_NOTIFICATIONS_ERROR:
            return { ...state, errorMessage: action.payload }

       
        default:
            return state;
    }
}

export default fiOrganizationNotifications;
