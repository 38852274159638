import {
  REQUEST_USERS_AVAILALBE_FOR_PROJECT_COMPLETED,
  RQST_CREATE_PROJECT_KEY_DONE,
  REQUEST_PROJECTS_COMPLETED,
  REQUEST_ACTIVE_PROJECT_BURNDOWN,
  REQUEST_PROJECT_EVENTS_SUCCESS
} from '../actions/focoosin.projects.actions.js';
import { truncateSync } from 'fs';

var moment = require('moment-timezone');

const INITIAL_STATE = {
  projectsList: null,
  usersAvailableForProject: [],
  activeProjectBurndownData: null,
  activeProjectEventsList: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {

    case REQUEST_USERS_AVAILALBE_FOR_PROJECT_COMPLETED:
      if (!action.error) {
        return { ...state, usersAvailableForProject: action.payload };
      }
      return { ...state, usersAvailableForProject: [] };

    case RQST_CREATE_PROJECT_KEY_DONE:
      return {
        ...state, newProjectKey: action.payload.project_code
      }

    case REQUEST_PROJECTS_COMPLETED:
      if (!action.error) {
        return { ...state, projectsList: action.payload };
      }

    case REQUEST_ACTIVE_PROJECT_BURNDOWN:
      return {
        ...state,
        activeProjectBurndownData: action.payload
      }

    case REQUEST_PROJECT_EVENTS_SUCCESS:
      return { ...state, activeProjectEventsList: action.payload }

    default:
      return state;
  }
}
