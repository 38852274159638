
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import { Row, Col, Progress, Tooltip, Button } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces, translate, Trans } from 'react-i18next';
import ProfilePicture from '../Common/ProfilePicture';
import {
    getProfileImageUrl,
    requestProjects,
    REQUEST_PROJECTS_COMPLETED
} from '../../store/actions/focoosin.projects.actions.js';
import BSTooltip from '../Common/BSTooltip';


class ProjectCard extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
        <div className="card card-default">
            <div className="card-header">
                <div className="float-right">
                { this.props.project.name }
                </div>
                <div className="card-title">{ this.props.project.public_key }</div>
            </div>
            <div className="card-body bt">
                { this.props.project.members.length > 0 && this.props.project.members.map(
                    m => <ProfilePicture key={m.user_key} user_key={m.user_key} display_user_name={m.display_user_name} size="small"/>
                    )
                }
                {
                    this.props.project.members.length == 0 && <div> <Trans i18nKey='projects.list.NOMEMBERS'>No members</Trans></div>
                }
                {/* <a className="inline mr-1" href="">
                    <img className="rounded-circle thumb24" src="img/user/02.jpg" alt="project member" />
                </a>
                <a className="inline mr-1" href="">
                    <img className="rounded-circle thumb24" src="img/user/04.jpg" alt="project member" />
                </a>
                <a className="inline mr-1" href="">
                    <img className="rounded-circle thumb24" src="img/user/05.jpg" alt="project member" />
                </a> */}
            </div>
{/*             <div className="card-body bb text-center">
                <BSTooltip content="Progress of asigned tasks">
                    <Progress className="progress-xs m-0" value="50" color="info" />
                </BSTooltip>
            </div>
            <div className="card-body bb">
                <div className="row text-center">
                    <div className="col-4">
                        <p className="text-bold">BUDGET</p>
                        <Sparkline values="60,40"
                            options={{
                                type: "pie",
                                height: "24",
                                sliceColors: ["#edf1f2", "#ff902b"]
                            }}
                            className="sparkline inline mr-2" />
                    </div>
                    <div className="col-4">
                        <p className="text-bold">TASKS</p>
                        <div className="h3 m-0">26</div>
                    </div>
                    <div className="col-4">
                        <p className="text-bold">AVAILABITY</p>
                        <Sparkline values="70,30"
                            options={{
                                type: "pie",
                                height: "24",
                                sliceColors: ["#edf1f2", "#ff902b"]
                            }}
                            className="sparkline inline mr-2" />
                    </div>
                </div>
            </div>
            <div className="card-body">
                <ul className="list-inline m-0">
                    <li className="list-inline-item">
                        <div className="badge bg-gray">angularjs</div>
                    </li>
                    <li className="list-inline-item">
                        <div className="badge bg-gray">jquery</div>
                    </li>
                    <li className="list-inline-item">
                        <div className="badge bg-gray">gulp</div>
                    </li>
                    <li className="list-inline-item">
                        <div className="badge bg-gray">git</div>
                    </li>
                    <li className="list-inline-item">
                        <div className="badge p-1 bg-gray">ios</div>
                    </li>
                </ul>
            </div>
 */}            <div className="card-footer text-center">
                <Link to={`/projects/${this.props.project.public_key}/`}><button type="button" className="btn btn-secondary btn-oval"> <Trans i18nKey='projects.list.MANAGEPROJECTBTON'>Manage Project</Trans></button></Link>
            </div>
        </div>
        )
    }
}

class ProjectsList extends Component {

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) {

    }

    retrieveSessionUserStatus() {

        let args = {
        }

        this.props.requestProjects(
            args,
            REQUEST_PROJECTS_COMPLETED,
            null,
            null
        )
    }

    componentDidMount() {
       // this.retrieveSessionUserStatus();
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading"><Trans i18nKey='projects.list.HEADER'>Project viewer</Trans>
                    <div className="ml-auto">
                        <Link to={'/new-project'} title={this.props.t('projects.list.CREATEPROJECTBTONTIP')}><Button type="button" color="warning">
                        <em className="fa fa-plus-circle fa-fw mr-1"></em> <Trans i18nKey='teams.list.CREATEPROJECTBTN'>Create Project</Trans> </Button></Link>
                    </div>
                </div>
                <Row>
                    

                        {/* end Team Card */}
                       {
                        this.props.projectsReducer.projectsList.map(p => <Col xl="4" lg="6"><ProjectCard key={p.public_key} project={p}/></Col>)
                       }

                    
                    
                    
                </Row>
            </ContentWrapper>
        );
    }

}


function mapStateToProps(state) {
    return {
        userProfileSettings: state.sessionUserSettings,
        projectsReducer: state.projectsReducer
    }
}

export default compose(
    connect(mapStateToProps, { requestProjects }),
    withNamespaces()
)(ProjectsList);
