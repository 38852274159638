
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import settingsReducer from './settings.reducer.js';
import themesReducer from './themes.reducers.js';

import finotificationsReducer from './finotifications.reducer.js';
import fiSessionUserSettings from './fisessionusersettings.reducer.js';
import fiFocusReducer from './fifocus.reducer.js'
import fiAccountSettingsReducer from './fiaccountsettings.reducer.js';
import fiTeamReducer from './fiteams.reducer.js';
import focoosin_projects_reducer from './fiprojects.reducer';

import fiStaticFormData from './fistaticformdata.reducer.js';
import fiExtendedUserProfile from './fiextendeduserprofile.reducer.js';

import fiUserNotificationSettings from './finotificationsettings.reducer.js';

import fiOrganizationNotifications from './fiorganizationnotifications.reducer.js';
import fiOrganizationInfo from './fiorganizationinfo.reducer.js';

import fiBillingInfo from './fibillinginfo.reducer.js';

import fiRolesSettings from './firolessettings.reducer.js';

import focoosin_tasks_reducer from './focoosin.tasks.reducer.js';

import fiOrganizationUserWorkingTime from './fiorguserworkingtime.reducer.js';

export default combineReducers({
    settings: settingsReducer,
    theme: themesReducer,
    notifications: finotificationsReducer,
    sessionUserSettings: fiSessionUserSettings,
    accountSettings: fiAccountSettingsReducer,
    focusReducer: fiFocusReducer,
    form: formReducer,
    teamsReducer:fiTeamReducer,
    projectsReducer:focoosin_projects_reducer,
    teamReducer: fiTeamReducer,
    extendedUserProfile: fiExtendedUserProfile,
    staticFormData: fiStaticFormData,
    userNotifSettings: fiUserNotificationSettings,
    tasksReducer: focoosin_tasks_reducer,
    orgNotifications: fiOrganizationNotifications,
    orgInfo: fiOrganizationInfo,

    billingInfo: fiBillingInfo,

    orgRolesSettings: fiRolesSettings,
    extendedUserWorkingTime: fiOrganizationUserWorkingTime
});
