import {
  REQUEST_TIME_PER_CATEGORY,
  REQUEST_CATEGORY_TIMELINE,
  REQUEST_USERS_AVAILALBE_FOR_FOCUS_VIEW_COMPLETED,
  RQST_FOCUS_TEAM_DASH_COMPLETED,
  RQST_FOCUS_LAST_EVENT_COMPLETED,
  RQST_FOCUS_ANY_EVENT_PER_USER_COMPLETED,
  RQST_FOCUS_ANY_EVENT_PER_USER_WITH_ERROR,
  RQST_FOCUS_USER_STATUS_COMPLETED,
  RQST_FOCUS_USER_STATUS_COMPLETED_ERROR
} from '../actions/focoosin.actions.js';
import { truncateSync } from 'fs';


import {
  UPDATE_FOCUS_INDIVIDUAL_DATE,
  UPDATE_FOCUS_INDIVIDUAL_USER,
  UPDATE_SELECTED_VIEW_AND_FILTERS,
  UPDATE_FOCUS_TEAM_DATE_RANGE
} from '../../store/actions/focoosin.uiselection.actions.js';

var moment = require('moment-timezone');

const INITIAL_STATE = {
  selectedLevel: 1,
  timePerCategory: [],
  categoryTimelineData: {time_zone:null, data:[]},
  usersAvailableForFocusView: [],
  usersAvailableForFocusLoading: false,
  teamSummaryData: [],
  teamMembersData: [],
  teamMaxTotalTime: null,
  lastEventPerUser: {},
  anyEventPerUser: null,
  userStatus: {},
  individualSelectedDate: new Date(),
  individualSelectedUser: null,
  teamSelectedStartDate: moment().startOf('day'),
  teamSelectedEndDate: moment().add(1, 'd').startOf('day'),
  viewAndFilters:{
    retrieved: false,
    selectedView: "panel.team",
    teamSelectedUsers: [],
    teamSelectedTeams: [],
    teamViewUsingTeams: true, // Its indicate if Team View, User is using Teams (true) or Users (false)
    showCategoriesIndividualView: true,
    showCategoriesTeamView: false
  }
  
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {

    case REQUEST_TIME_PER_CATEGORY:
      if (!action.error && action.payload.length >= 0) {
        return { ...state, timePerCategory: action.payload };
      }
      return { ...state, timePerCategory: [] };

    case REQUEST_CATEGORY_TIMELINE:
      if (!action.error && action.payload.data.length >= 0) {
        return { ...state, categoryTimelineData: action.payload };
      }

    case REQUEST_USERS_AVAILALBE_FOR_FOCUS_VIEW_COMPLETED:
      if (!action.error && action.payload.length >= 0) {
        return {
          ...state,
          usersAvailableForFocusView: action.payload,
          usersAvailableForFocusLoading: false
        }
      }

    case RQST_FOCUS_TEAM_DASH_COMPLETED:
      if (!action.error) {
        return {
          ...state,
          teamSummaryData: action.payload.summary,
          teamMembersData: action.payload.users,
          teamMaxTotalTime: action.payload.max_total_time
        }
      }

    case RQST_FOCUS_LAST_EVENT_COMPLETED:
      if (!action.error) {
        return {
          ...state,
          lastEventPerUser: action.payload
        }
      }

    case RQST_FOCUS_ANY_EVENT_PER_USER_COMPLETED:
      if (!action.error) {
        return {
          ...state,
          anyEventPerUser: { ...action.payload, error: false }
        }
      }

    case RQST_FOCUS_ANY_EVENT_PER_USER_WITH_ERROR:
      return {
        ...state,
        anyEventPerUser: { error: truncateSync }
      }

    case RQST_FOCUS_USER_STATUS_COMPLETED:
      if (!action.error) {
        return {
          ...state,
          userStatus: action.payload
        }
      }

    case UPDATE_FOCUS_INDIVIDUAL_DATE:
      return {
        ...state, individualSelectedDate: action.payload
      }

    case UPDATE_FOCUS_INDIVIDUAL_USER:
      return {
        ...state, individualSelectedUser: action.payload
      }

    // Selected date range has been updated
    case UPDATE_FOCUS_TEAM_DATE_RANGE:

      return {
        ...state,
        teamSelectedStartDate: action.payload.startDate,
        teamSelectedEndDate: action.payload.endDate
      }

    // UPDATE USER SELECTED VIEW (REMOTELY)
    case UPDATE_SELECTED_VIEW_AND_FILTERS:
      return {
        ...state, viewAndFilters: action.payload
      }
    
    // Selected user has been updated

    case RQST_FOCUS_USER_STATUS_COMPLETED_ERROR:
      return {
        ...state,
        userStatus: {}
      }

      return {
        ...state,
        teamSummaryData: [],
        teamMembersData: [],
        teamMaxTotalTime: null
      }

    default:
      return state;
  }
}
