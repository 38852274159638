import React, { Component } from 'react';
import {Tooltip } from 'reactstrap';
/**
 * Wrap an element and assign automatically an ID,
 * creates handler for show/hide tooltips without
 * the hassle of creating new states and class methods.
 * Support only one child and simple text.
 */

class BSTooltip extends Component {
    // static propTypes { content: PropTypes.string }
    state = {
        _id: 'id4tooltip_' + new Date().getUTCMilliseconds(),
        tooltipOpen: false
    }
    toggle = () => this.setState({ tooltipOpen: !this.state.tooltipOpen })
    render() {
        return [
            <Tooltip {...this.props} isOpen={this.state.tooltipOpen} toggle={this.toggle} target={this.state._id} key='1'>
                {this.props.content}
            </Tooltip>,
            React.cloneElement(React.Children.only(this.props.children), {
                id: this.state._id,
                key: '2'
            })
        ]
    }
}
export default BSTooltip;