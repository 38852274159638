import {
    RETRIEVE_EXTENDED_USERPROFILE_COMPLETED,
    RETRIEVE_EXTENDED_USERPROFILE_ERROR,

    REQUEST_CONNECTION_FAILED,
    REQUEST_UNAUTHENTICATED
} from '../actions/actions';

const initialState = {
    usrsettings: {},

    globalConnectionError: '',
    globalAuthenticated: true
}

const fiExtendedUserProfile = (state = initialState, action) => {

    switch (action.type) {
        case RETRIEVE_EXTENDED_USERPROFILE_COMPLETED:
            if (action.error) {
                //action = logout_on_401(action);
                return { ...state, usrsettings: undefined};
            }
            else
                return {
                    ...state,
                    usrsettings: action.payload
                };


        case RETRIEVE_EXTENDED_USERPROFILE_ERROR:
            return { ...state, errorMessage: action.payload }






        case REQUEST_CONNECTION_FAILED:
            return {
                ...state,
                globalConnectionError: action.payload
            }
        case REQUEST_UNAUTHENTICATED:
            return {
                ...state,
                globalAuthenticated: false
            }
        default:
            return state;
    }
}

export default fiExtendedUserProfile;
