import React, { Component } from 'react';
import { withNamespaces, translate, Trans } from 'react-i18next';
import {Link } from 'react-router-dom';
import { compose } from 'redux';
class Footer extends Component {

    render() {
        const year = new Date().getFullYear()
        return (
            <footer className="footer-container">
                <span>Focoosin</span> <small className="text-right"> | <a href="https://www.focoosin.com/?home"><Trans i18nKey='footer.home'>Home</Trans></a> | <a href="https://www.focoosin.com/?home"><Trans i18nKey='footer.supportchat'>Support chat</Trans></a> <div className="d-none d-md-inline">| <a  href="https://www.focoosin.com/download/"><Trans i18nKey='footer.downloaddesktop'>Download Focoosin Desktop</Trans></a></div> | <span></span><a href="https://www.focoosin.com/help/"><Trans i18nKey='footer.help'>Help</Trans></a> </small>
            </footer>
        );
    }

}

export default compose(
    translate('translations')
  )(Footer);
