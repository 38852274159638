import React, { Component } from 'react';
import { render } from 'react-dom';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import { Card, CardHeader, Container, CardBody, Input } from 'reactstrap';
import $ from 'jquery';
// Wizard (jquery.steps)
import 'jquery-validation/dist/jquery.validate.js';
import 'jquery-steps/build/jquery.steps.min.js';

import { Link } from 'react-router-dom';
import {
  Progress,
  Button,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  FormText
} from 'reactstrap';


// React Select
import Select from 'react-select';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces, Trans } from 'react-i18next';

import {

} from '../../store/actions/focoosin.actions.js';

import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

var moment = require('moment-timezone');

class ProjectsListEmpty extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }
  


  componentDidUpdate(prevProps, prevState) {



  }



  render() {

    const userProfileSettings = this.props.userProfileSettings;

    return (

      <ContentWrapper>
        <div className="unwrap">
          <div className="bg-cover">
            <Container className="container-md py-4">
              <div className="text-center mb-3 pb-3">
                <div className="h1 text-bold"><Trans i18nKey='teams.empty.TITLE'>There are not teams created yet</Trans></div>
              </div>
            </Container>
          </div>
        </div>
        <Container className="container-md">
        
          <p className="text-center">
          <Trans i18nKey='teams.empty.SHORT_DESCRIPTION'>Configure users in Focoosin in the same way they are organized  in your organization.</Trans>
          </p>
          <br/>
          <Row>
            <Col md="6">
              <div className="animated fadeInLeftBig">
                <div className="card-body text-center">
                    <img className="mr-3" src="img/team2.png" alt="Welcome" />
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="">
                <div className="card-body text-left">
                <br/>
                <p className="animated fadeInLeft"><Link to='/new-team'><Button color="warning" className="btn-oval" size="lg"><Trans i18nKey='teams.empty.BTN_CREATE'>Create Team</Trans></Button></Link></p>
                <br/>
                <h4 className="text-muted text-left"><Trans i18nKey='teams.empty.CREATE_TAGLINE'>Use the Teams to organize users, display information about them in the dashboard, and more!</Trans></h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">

            </Col>
          </Row>
        </Container>
      </ContentWrapper>
    );

  }
}


function mapStateToProps(state) {

  return {
    userProfileSettings: state.sessionUserSettings
  }
}
export default compose(
  connect(mapStateToProps, {
  })
)(ProjectsListEmpty);
