import axios from 'axios';
import store from '../store';

export * from './focoosin.actions.js';

// PROJECTs
export const POST_CREATE_PROJECT_DONE = 'POST_CREATE_PROJECT_DONE';
export const RQST_USERS_AVAILALBE_FOR_PROJECT_DONE = 'RQST_USERS_AVAILALBE_FOR_PROJECT_DONE';
export const RQST_CREATE_PROJECT_KEY_DONE = 'RQST_CREATE_PROJECT_KEY_DONE';
export const RQST_UPDATE_PROJECT_COMPLETED = 'RQST_UPDATE_PROJECT_COMPLETED';
export const RQST_UPDATE_PROJECT_FAILED = 'RQST_UPDATE_PROJECT_FAILED';
export const REQUEST_PROJECTS_COMPLETED = 'REQUEST_PROJECTS_COMPLETED';
export const REQUEST_USERS_AVAILALBE_FOR_PROJECT_COMPLETED = 'REQUEST_USERS_AVAILALBE_FOR_PROJECT_COMPLETED';

// Projects
export const REQUEST_DASHBOARD_PROJECTS = 'REQUEST_DASHBOARD_PROJECTS';
export const REQUEST_ACTIVE_PROJECT = 'REQUEST_ACTIVE_PROJECT';
export const REQUEST_ACTIVE_PROJECT_BURNDOWN = 'REQUEST_ACTIVE_PROJECT_BURNDOWN';
export const REQUEST_PROJECT_EVENTS_SUCCESS = 'REQUEST_PROJECT_EVENTS_SUCCESS';
export const REQUEST_POST_PROJECT_CREATED = 'REQUEST_POST_PROJECT_CREATED';



const ERROR_WITH_MESSAGE = [400, 403];

export function requestProjects(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {
      if (on_start_dispatch_type)
        dispatch({ type: on_start_dispatch_type, payload: [] });

      const response = await axios.get('/api/latest/projects/',
        {
          params
        });

      dispatch({ type: on_success_dispatch_type, payload: response.data });
    } catch (e) {
      console.log('Error on requestFilteredProjects action', e);
    } finally {

    }
  }
}


export function updateProject(project_key, payload, on_success_callback, on_error_callback) {
  return async (dispatch) => {

    const response = await axios(`/api/latest/projects/${project_key}/`, {
      method: "put",
      data: payload
    }).then(
      (response) => {
        dispatch({ type: 'a', payload: response.data })
        if (on_success_callback !== undefined) return on_success_callback(response)
      }
    ).catch((err) => {
      console.log(err.response.status);

      if (ERROR_WITH_MESSAGE.includes(err.response.status)) {
        on_error_callback({
          response: JSON.parse(err.request.response)
        });
      }
      else {
        on_error_callback({
          response: { user_message: 'Error' }
        });
      }
    })
  }
}


export function deleteProject(project_key, on_success_callback, on_error_callback) {
  return async (dispatch) => {

    const response = await axios(`/api/latest/projects/${project_key}/`, {
      method: "delete"
    }).then(
      (response) => {
        dispatch({ type: 'a', payload: response.data })
        if (on_success_callback !== undefined) return on_success_callback(response)
      }
    ).catch((err) => {
      console.log(err.response.status);

      if (ERROR_WITH_MESSAGE.includes(err.response.status)) {
        on_error_callback({
          response: JSON.parse(err.request.response)
        });
      }
      else {
        on_error_callback({
          response: { user_message: 'Error' }
        });
      }
    })
  }
}


export function requestProjectBurndownData(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {
      if (on_start_dispatch_type)
        dispatch({ type: on_start_dispatch_type, payload: [] });

      const response = await axios.get('/api/burndown/',
        {
          params
        });

      dispatch({ type: on_success_dispatch_type, payload: response.data });
    } catch (e) {
      console.log('Error on requestProjectBurndownData action', e);
    } finally {

    }
  }
}

export function requestProjectEvents(
  projectId,
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type
) {
  return async (dispatch) => {
    try {
      if (on_start_dispatch_type)
        dispatch({ type: on_start_dispatch_type, payload: [] });

      const response = await axios.get(`/api/project/${projectId}/events/`,
        {
          params
        });

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (e) {
      console.log('Error executing requestProjectEvents');
    }

  }
}

export function createProject(
  values,
  on_success_dispatch,
  on_success_callback,
  on_error_callback
) {
  const request = axios('/api/latest/projects/', {
    method: "post",
    data: values,
  }).then(
    (response) => on_success_callback(response)
  ).catch(
    (error) => on_error_callback(error)
  );
  return {
    type: on_success_dispatch,
    payload: request.data
  }
}

export function generateProjectKey(projectName, on_success_dispatch) {
  return async (dispatch) => {
    const response = await axios('/api/latest/project-key-generator/',
      {
        method: "post",
        data: { project_name: projectName },
      })

    dispatch({ type: on_success_dispatch, payload: response.data });

  }
}

export function removeProjectAsFavorite(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null,
  on_success_callback = null,
  on_error_callback = null
) {
  return updateProjectAsFavorite(
    '/api/latest/remove-favorite-project/',
    params,
    on_success_dispatch_type,
    on_start_dispatch_type,
    on_error_dispatch_type,
    on_success_callback,
    on_error_callback
  )
}

export function addProjectAsFavorite(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null,
  on_success_callback = null,
  on_error_callback = null
) {
  return updateProjectAsFavorite(
    '/api/latest/favorite-project/',
    params,
    on_success_dispatch_type,
    on_start_dispatch_type,
    on_error_dispatch_type,
    on_success_callback,
    on_error_callback
  )
}

export function updateProjectAsFavorite(
  url,
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null,
  on_success_callback = null,
  on_error_callback = null
) {
  return async (dispatch) => {
    if (on_start_dispatch_type) {
      dispatch({ type: on_start_dispatch_type, payload: [] });
    }
    const response = await axios(url, {
      method: "post",
      data: params,
    }).then(
      (response) => {
        dispatch({ type: on_success_dispatch_type, payload: response.data });
        if (on_success_callback !== null) {
          on_success_callback(response);
        }
      }
    ).catch(e => {
      console.log('Error on requestprojects action', e);
      if (on_error_dispatch_type != null)
        dispatch({ type: on_error_dispatch_type, payload: null });
      if (on_error_callback != null) {
        on_error_callback(e.request.response);
      }
    })
  }
}