import React, { Component } from 'react';
import { translate, Trans } from 'react-i18next';
import { Redirect, Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../store/actions/actions';

const LinkStyle = {
  color: '#000000'
};

class WebNotifications extends Component
{
  constructor(props)
  {
    super(props);
    this.state = { navigateTo:null };
    //this.handleOnClick = this.handleOnClick.bind(this);
  }

  // handleOnClick(event, button_link){
  //   event.preventDefault();
  //   this.setState({navigateTo:button_link});
  //
  // }

  renderNotificationIcon(category)
  {
    switch(category)
    {
      case 'task':
        return <em className="fa fa-tasks fa-2x text-warning"></em>;
      case 'project':
        return <em className="fa fa-briefcase fa-2x text-warning"></em>;
      case 'comment':
        return <em className="fa fa-comment-alt fa-2x text-info"></em>;
      default:
        return null;
    }
  }
  renderNotification()
  {
    return (
      this.props.notifications.map(
      (notification) => (
        <ListGroupItem action key={ notification.id }>
           <div className="media">
              <div className="align-self-start mr-2">
                 { this.renderNotificationIcon(notification.category) }
              </div>
              <Link to={ notification.button1_link }>
              <div className="media-body">
                 <p className="m-0 " style={LinkStyle}>{ notification.title }</p>
                 <p className="m-0 text-muted text-sm">{ notification.message }</p>
                 <p className="m-0 text-muted text-sm"><em><small>{ notification.human_time }</small></em></p>
                 {/* <p className="m-0 text-muted text-sm far fa-clock">{ notification.human_time }</p> */}
              </div>
              </Link>
           </div>
        </ListGroupItem>
      )
    )
  )
  }

  //this.renderNotification = renderNotification.bind(this);

  render()
  {
    
    const remmaining_notifications = this.props.total - this.props.notifications.length;
    return (
      // { /* START Alert menu */ }
      <UncontrolledDropdown nav inNavbar className="dropdown-list">
          <DropdownToggle nav className="dropdown-toggle-nocaret">
              <em className="icon-bell"></em>
              { this.props.total > 0 && <span className="badge badge-danger">{this.props.total}</span>}

          </DropdownToggle>
          { /* START Dropdown menu */ }
          <DropdownMenu right className="dropdown-menu-right animated fadeInDown">
              <DropdownItem>
                  { /* START list group */ }
                  <ListGroup>
                      {this.renderNotification()}
                      <ListGroupItem action tag="a" href="" onClick={e => e.preventDefault()}>
                         <span className="d-flex align-items-center">
                            <span className="text-sm"><Trans i18nKey='notifications.SEEMORE'>See more notifications</Trans></span>
                            <span className="badge badge-danger ml-auto">{remmaining_notifications}</span>
                         </span>
                      </ListGroupItem>
                  </ListGroup>
                  { /* END list group */ }
              </DropdownItem>
          </DropdownMenu>
          { /* END Dropdown menu */ }
      </UncontrolledDropdown>
      //{ /* END Alert menu */ }

    )
  }
}
function mapStateToProps(state)
{
  return {
    notifications:state.notifications.notifications,
    total:state.notifications.total
  }
}

export default compose(connect(mapStateToProps, actions)) (WebNotifications);
