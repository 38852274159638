import React, { Component } from 'react';
import { render } from 'react-dom';
import { Progress, Row, Col, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Tooltip } from 'reactstrap';
import { ResponsiveLine } from '@nivo/line';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate, Trans } from 'react-i18next';
import {REQUEST_ACTIVE_PROJECT_BURNDOWN, requestProjectBurndownData }  from '../../store/actions/focoosin.projects.actions.js';

import * as moment from 'moment';
import ProjectEventList from './ProjectEventList';
import TeamWorkingOnList from './TeamWorkingOnList';

const ChartHeight = {
  height: '200px'
};


class BSTooltip extends Component {
  // static propTypes { content: PropTypes.string }
  state = {
    _id: 'id4tooltip_'+new Date().getUTCMilliseconds(),
    tooltipOpen: false
  }
  toggle = e => {
    this.setState({tooltipOpen: !this.state.tooltipOpen});
  }
  render() {
    return [
      <Tooltip {...this.props} isOpen={this.state.tooltipOpen} toggle={this.toggle} target={this.state._id} key='1'>
        {this.props.content}
      </Tooltip>,
      React.cloneElement(React.Children.only(this.props.children), {
        id: this.state._id,
        key: '2'
      })
    ]
  }
}

class ProjectOverviewView extends Component
{
  constructor (state)
  {
    super(state);
    this.state = {
      dropdownTranslateOpen: false,
      dropdownCardOpen: false,
      dropdownChartOpen: false,

    }
  }
  toggleDDTranslate = () => {
    this.setState({
      dropdownTranslateOpen: !this.state.dropdownTranslateOpen
    });
  }

  toggleDD = () => {
    this.setState({
      dropdownCardOpen: !this.state.dropdownCardOpen
    });
  }

  toggleDDChart = () => {
    this.setState({
      dropdownChartOpen: !this.state.dropdownChartOpen
    });
  }

  requestProjectBurndownData(activeProject){

    const end_date = this.props.activeProject.due_date && this.props.activeProject.is_closed? this.props.activeProject.due_date : new Date();

    this.props.requestProjectBurndownData(
      {
        p: this.props.activeProject.id,
        start_date: this.props.activeProject.created_on,
        end_date: end_date
      },
      REQUEST_ACTIVE_PROJECT_BURNDOWN,
      null,
      null
    )
  }
  componentDidUpdate(prevProps){

    if (prevProps.activeProject !== this.props.activeProject)
    {
      if (this.props.activeProject)
      {
        this.requestProjectBurndownData(this.props.activeProject);
      }
    }
  }

  componentWillMount(){
    if (this.props.activeProject)
    {
      this.requestProjectBurndownData(this.props.activeProject);
    }
  }

  render()
  {
    const project = this.props.activeProject;
    const userProfileSettings = this.props.userProfileSettings;
    const projectCompletion = project ? project.perc_tsk_cmp * 100 : null;
    const closedTasks = project ? project.cnt_tasks - project.cnt_completed_tasks: null;

    return (

      <div>
        <Row>
          <Col xl={ 12 }>
            { /* START bar chart */ }
            <div className="card" id="cardChart3">
              <div className="card-header">
                {/* <Dropdown className="float-right" isOpen={this.state.dropdownCardOpen} toggle={this.toggleDD}>
                <DropdownToggle className="btn-sm">Monthly</DropdownToggle>
                <DropdownMenu className="dropdown-menu-right-forced fadeInLeft animated">
                <DropdownItem>Daily</DropdownItem>
                <DropdownItem>Monthly</DropdownItem>
                <DropdownItem>Yearly</DropdownItem>
              </DropdownMenu>
            </Dropdown> */}
            <div className="card-title">Projects Hours</div>
          </div>
          <div style={ChartHeight} >
            {/* <FlotChart data={this.state.barStackedData} options={this.state.barStackedOptions} height="250px" /> */}

       

            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-2 col-6 text-center">
                    <p>Progress</p>
                    <div className="h3">{Number.parseFloat(projectCompletion ? projectCompletion : 0.0).toFixed(0) }%</div>
                    <div className="d-flex align-items-center">
                      <div className="w-100" data-title="Health">
                        <Progress className="progress-xs m-0" value={ projectCompletion ? projectCompletion : 0 } color="warning"/>

                      </div>
                    </div>

                  </div>
                  <div className="col-md-2 col-6 text-center">
                    <p>Status</p>
                    <div className="h3">{project ? project.status_name: '-'}</div>
                  </div>
                  <div className="col-md-2 col-6 text-center">
                    <p>Open Tasks</p>
                    <div className="h3">{project ? project.cnt_completed_tasks:'-'}</div>
                  </div>
                  <div className="col-md-2 col-6 text-center">
                    <p>Closed Tasks</p>
                    <div className="h3">{closedTasks?closedTasks:'-'}</div>
                  </div>
                  <div className="col-md-2 col-6 text-center">
                    <p>Your pending</p>
                    <div className="h3 text-truncate">{ project ? project.tasks_assigned_user:'-' }</div>
                  </div>
                  <div className="col-md-2 col-6 text-center">
                    <p>Due date</p>
                    <div className="h4 text-truncate">{project ? moment(project.due_date).fromNow() : '-'}</div>
                    <div className="h5 text-truncate">{project ? moment(project.due_date).format(userProfileSettings.dateFormat) : '-'}</div>
                  </div>
                </div>
              </div>
            </div>
            { /* END bar chart */ }
          </Col>
        </Row>
        <Row>
            <Col xl={ 5 }>
              <div className="card" id="cardChart3">
                  <div className="card-header">
                      <div className="card-title">Projects Hours</div>
                  </div>
                  <div className="card-body">
                      <ProjectEventList projectId={ project ? project.id : null }/>
                  </div>
              </div>
            </Col>
            <Col xl={ 7 }>
                { /* START bar chart */ }
                <div className="card" id="cardChart3">
                    <div className="card-header">
                        <div className="card-title">Projects Hours</div>
                    </div>
                    <div className="card-body">
                        <TeamWorkingOnList projectKey={ project?project.public_key:null }/>
                    </div>
                </div>
                { /* END bar chart */ }
            </Col>
        </Row>
      </div>
    )
  }
}

function mapBurndowDataToChart(activeProjectBurndownData)
{
  if (!activeProjectBurndownData)
  return [];
  let burndownDataComplted = [];
  let burndownDataPending = [];

  activeProjectBurndownData.forEach( function(record){
    burndownDataComplted.push({ x: record.date, y: record.cnt_completed_tsks });
    burndownDataPending.push({ x: record.date, y: record.cnt_pending_tasks });
  });
  //return activeProjectBurndownData.map((record) =>
  //({ completed_tasks: record.cnt_completed_tsks, pending_tasks: record.cnt_pending_tasks })
  let burndownData = [
    {id: 'Completed',color: '#000000',data:burndownDataComplted},
    {id: 'Pending',color: '#7266ba',data:burndownDataPending}
  ];

  return burndownData

}
function mapStateToProps(state, ownProps){
  const p = state.projectsReducer.projectsList.find(function (element) {
    return element.public_key == ownProps.match.params.projectcode;
});

  return {
    activeProject:p,
    activeProjectBurndownData:[] , //mapBurndowDataToChart(state.projectsReducer.activeProjectBurndownData),
    userProfileSettings: state.sessionUserSettings
  }
}
export default compose(
  translate('translations'),
  connect(mapStateToProps, {requestProjectBurndownData})
)(ProjectOverviewView);
