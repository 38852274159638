import {
    RECEIVE_PROFILE_INFO_BASIC,
    ERROR_PROFILE_INFO_BASIC,
    RECEIVE_USER_MENU_ITEMS,
    RQST_FOCUS_DATARECEIVED_COMPLETED,
    REQUEST_CONNECTION_FAILED,
    REQUEST_UNAUTHENTICATED
} from '../actions/actions';

import {
    REFRESH_PROFILE_IMG_TIMESTAMP,
    REFRESH_ORGANIZATION_LOGO_TIMESTAMP

} from '../actions/focoosin.actions';


const initialState = {
    userKey: null,
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    errorMessage: '',
    languageCode: 'en',
    timezone: 'America/Los_Angeles',
    dateFormat: 'DD-MM-YYYY',
    timeFormat: 'HH:mm',
    datetimeFormat: 'DD-MM-YYYY HH:mm',
    monthDateFormat: 'MMM DD',
    menuItems: [],
    timelineStartHour: 9,
    timelineEndHour: 18,
    isFirstUsage: null,
    noMonitoringDataReceived: null,
    globalConnectionError: '',
    globalAuthenticated: true,
    profileImageTimeStamp: (new Date()).getSeconds(),
    OrganizationLogoTimeStamp: (new Date()).getSeconds(),
    menuProfile: []
}

const fiSessionUserSettings = (state = initialState, action) => {

    switch (action.type) {
        case RECEIVE_PROFILE_INFO_BASIC:
            if (action.error) {
                //action = logout_on_401(action);
                return { ...state, firstName: 'Something went wrong' };
            }
            else
                return {
                    ...state,
                    userKey: action.payload.user_key,
                    firstName: action.payload.first_name,
                    lastName: action.payload.last_name,
                    fullName: action.payload.full_name,
                    languageCode: action.payload.language_code,
                    timezone: action.payload.time_zone,
                    dateFormat: action.payload.js_date_format,
                    timeFormat: action.payload.js_time_format,
                    datetimeFormat: action.payload.js_datetime_format,
                    timelineStartHour: action.payload.timeline_start_hour,
                    timelineEndHour: action.payload.timeline_end_hour
                };


        case ERROR_PROFILE_INFO_BASIC:
            return { ...state, errorMessage: action.payload }

        case RECEIVE_USER_MENU_ITEMS:
            return { ...state, menuItems: action.payload.main, menuProfile:action.payload.profile }


        case RQST_FOCUS_DATARECEIVED_COMPLETED:
            if (!action.error) {
                return {
                    ...state,
                    noMonitoringDataReceived: action.payload[state.userKey].last_event_epoch === null
                }

            }

        case REQUEST_CONNECTION_FAILED:
            return {
                ...state,
                globalConnectionError: action.payload
            }
        case REQUEST_UNAUTHENTICATED:
            return {
                ...state,
                globalAuthenticated: false
            }
        case REFRESH_PROFILE_IMG_TIMESTAMP:
            return {
                ...state,
                profileImageTimeStamp: (new Date()).getSeconds()
            }

        case REFRESH_ORGANIZATION_LOGO_TIMESTAMP:
            return {
                ...state,
                OrganizationLogoTimeStamp: (new Date()).getSeconds()
            }
            default:
            return state;
    }
}

export default fiSessionUserSettings;
