import axios from 'axios';
import store from '../store';
import { resolve } from 'path';
const { dispatch } = store; // direct access to redux store.

// Common
export const REQUEST_CONNECTION_FAILED = 'REQUEST_CONNECTION_FAILED';
export const REQUEST_UNAUTHENTICATED = 'REQUEST_UNAUTHENTICATED';

// User Profile
export const REQUEST_PROFILE_INFO_BASIC = 'REQUEST_PROFILE_INFO_BASIC';
export const RECEIVE_PROFILE_INFO_BASIC = 'RECEIVE_PROFILE_INFO_BASIC';
export const ERROR_PROFILE_INFO_BASIC = 'ERROR_PROFILE_INFO_BASIC';
export const RECEIVE_USER_MENU_ITEMS = 'RECEIVE_USER_MENU_ITEMS';
export const REFRESH_PROFILE_IMG_TIMESTAMP = 'REFRESH_PROFILE_IMG_TIMESTAMP';
export const REFRESH_ORGANIZATION_LOGO_TIMESTAMP = 'REFRESH_ORGANIZATION_LOGO_TIMESTAMP';

// Users / members
export const REQUEST_POST_INVITE_USERS_COMPLETED = 'REQUEST_POST_INVITE_USERS_COMPLETED';

// Organization 

export const RETRIEVE_ORG_USER_WORKING_TIME_COMPLETED = 'RETRIEVE_ORG_USER_WORKING_TIME_COMPLETED';
export const RETRIEVE_ORG_USER_WORKING_TIME_ERROR = 'RETRIEVE_ORG_USER_WORKING_TIME_ERROR';

export const RETRIEVE_ORG_NOTIFICATIONS_COMPLETED = 'RETRIEVE_ORG_NOTIFICATIONS_COMPLETED';
export const RETRIEVE_ORG_NOTIFICATIONS_ERROR = 'RETRIEVE_ORG_NOTIFICATIONS_ERROR';

export const RETRIEVE_ORG_INFO_COMPLETED = 'RETRIEVE_ORG_INFO_COMPLETED';
export const RETRIEVE_ORG_INFO_ERROR = 'RETRIEVE_ORG_INFO_ERROR';

export const RETRIEVE_ORG_LOGO_COMPLETED = 'RETRIEVE_ORG_LOGO_COMPLETED';
export const RETRIEVE_ORG_LOGO_ERROR = 'RETRIEVE_ORG_LOGO_ERROR';


// Tasks
export const REQUEST_TASKS_DUEDATE_TODAY = 'REQUEST_TASKS_DUEDATE_TODAY';
export const REQUEST_TASKS_DUEDATE_AFTERTODAY = 'REQUEST_TASKS_DUEDATE_AFTERTODAY';
export const REQUEST_TASKS_DUEDATE_BEFORETODAY = 'REQUEST_TASKS_DUEDATE_BEFORETODAY';
export const REQUEST_TASK_COUNT_ASSIGNED = 'REQUEST_TASK_COUNT_ASSIGNED';

// Logout?

// Notifications
export const RECEIVE_USER_NOTIFICATIONS = 'RECEIVE_USER_NOTIFICATIONS';

export const RETRIEVE_USER_NOTIFICATIONSETTINGS_COMPLETED = 'RETRIEVE_USER_NOTIFICATIONSETTINGS_COMPLETED';
export const RETRIEVE_USER_NOTIFICATIONSETTINGS_ERROR = 'RETRIEVE_USER_NOTIFICATIONSETTINGS_ERROR';

//Global Search
export const REQUEST_GLOBAL_SEARCH = 'REQUEST_GLOBAL_SEARCH';
export const LOADING_GLOBAL_SEARCH = 'LOADING_GLOBAL_SEARCH';

// Working On
export const REQUEST_ACTIVE_TASK = 'REQUEST_ACTIVE_TASK';
export const REQUEST_DASHBOARD_WORKING_ON = 'REQUEST_DASHBOARD_WORKING_ON';
export const REQUEST_MYTASKS_SEARCH = 'REQUEST_MYTASKS_SEARCH';

// Focus
export const REQUEST_TIME_PER_CATEGORY = 'REQUEST_TIME_PER_CATEGORY';
export const REQUEST_CATEGORY_TIMELINE = 'REQUEST_CATEGORY_TIMELINE';
export const REQUEST_USERS_AVAILALBE_FOR_FOCUS_VIEW_COMPLETED = 'REQUEST_USERS_AVAILALBE_FOR_FOCUS_VIEW_COMPLETED';
export const RQST_FOCUS_TEAM_DASH_COMPLETED = 'RQST_FOCUS_TEAM_DASH_COMPLETED';
export const RQST_FOCUS_LAST_EVENT_COMPLETED = 'RQST_FOCUS_LAST_EVENT_COMPLETED';
export const RQST_FOCUS_DATARECEIVED_COMPLETED = 'RQST_FOCUS_DATARECEIVED_COMPLETED'
export const RQST_FOCUS_ANY_EVENT_PER_USER_COMPLETED = 'RQST_FOCUS_ANY_EVENT_PER_USER_COMPLETED';
export const RQST_FOCUS_ANY_EVENT_PER_USER_WITH_ERROR = 'RQST_FOCUS_ANY_EVENT_PER_USER_WITH_ERROR';
export const RQST_FOCUS_USER_STATUS_COMPLETED = 'RQST_FOCUS_USER_STATUS_COMPLETED';
export const RQST_FOCUS_USER_STATUS_COMPLETED_ERROR = 'RQST_FOCUS_USER_STATUS_COMPLETED_ERROR';
export const UPDATE_FOCUS_USER_SHOW_CATEGORIES = 'UPDATE_FOCUS_USER_SHOW_CATEGORIES';

// Account Settings
export const RETRIEVE_ACCOUNT_USERS_COMPLETED = 'RETRIEVE_ACCOUNT_USERS_COMPLETED';

// TIMEZONE
export const RETRIEVE_TIMEZONES_COMPLETED = 'RETRIEVE_TIMEZONES_COMPLETED';
export const RETRIEVE_TIMEZONES_ERROR = 'RETRIEVE_TIMEZONES_ERROR';

export const RETRIEVE_LANGUAGES_COMPLETED = 'RETRIEVE_LANGUAGES_COMPLETED';
export const RETRIEVE_LANGUAGES_ERROR = 'RETRIEVE_LANGUAGES_ERROR';

export const RETRIEVE_TIMEFORMAT_COMPLETED = 'RETRIEVE_TIMEFORMAT_COMPLETED';
export const RETRIEVE_TIMEFORMAT_ERROR = 'RETRIEVE_TIMEFORMAT_ERROR';

export const RETRIEVE_COUNTRIES_COMPLETED = 'RETRIEVE_COUNTRIES_COMPLETED';
export const RETRIEVE_COUNTRIES_ERROR = 'RETRIEVE_COUNTRIES_ERROR';

export const RETRIEVE_DATEFORMAT_COMPLETED = 'RETRIEVE_DATEFORMAT_COMPLETED';
export const RETRIEVE_DATEFORMAT_ERROR = 'RETRIEVE_DATEFORMAT_ERROR';

export const RETRIEVE_VALIDTIMES_COMPLETED = 'RETRIEVE_VALIDTIMES_COMPLETED';
export const RETRIEVE_VALIDTIMES_ERROR = 'RETRIEVE_VALIDTIMES_ERROR';

export const RETRIEVE_EXTENDED_USERPROFILE_COMPLETED = 'RETRIEVE_EXTENDED_USERPROFILE_COMPLETED';
export const RETRIEVE_EXTENDED_USERPROFILE_ERROR = 'RETRIEVE_EXTENDED_USERPROFILE_ERROR';

//Roles

export const RETRIEVE_ROLES_COMPLETED = 'RETRIEVE_ROLES_COMPLETED';
export const RETRIEVE_ROLES_ERROR = 'RETRIEVE_ROLES_ERROR';

export const baseURL = 'https://www.focoosin.com';
//export const baseURL = 'http://localhost:8000';

const UNAUTHORIZED = 403;
const UNAUTHENTICATED = 401;

const ERROR_WITH_MESSAGE = [400, 401, 402, 403];

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

// Set config defaults when creating the instance
// This instance of Axios will be used for request not hitting Focoosin
const nonFocoosinInstance = axios.create({
  baseURL: 'https://api.example.com'
});

const defaulRequestTimeoutms = 20000;

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  if (error.response) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js


    console.log('interceptors.error.response', error);

    if (error.response.status === UNAUTHENTICATED) {
      window.location = baseURL;
      dispatch({ type: REQUEST_UNAUTHENTICATED, payload: error.message ? error.message : 'UNAUTHENTICATED' });
    }

  } else if (error.request) {
    dispatch({ type: REQUEST_CONNECTION_FAILED, payload: error.message ? error.message : 'Error' });
    console.log('interceptors.error.request', error);
  } else {
    dispatch({ type: REQUEST_CONNECTION_FAILED, payload: 'Error' });
    console.log('interceptors.error', error);
  }
  return Promise.reject(error);
});

export function requestProfileInfoBasic() {

  return async (dispatch) => {
    //because we are using Redux Thunk, we can return a function which be call
    //with dispatch
    try {
      const response = await axios.get('/api/latest/user/');
      dispatch({ type: RECEIVE_PROFILE_INFO_BASIC, payload: response.data });
    } catch (e) {
      dispatch({ type: ERROR_PROFILE_INFO_BASIC, payload: 'Error' });
      //if (e.response !== undefined && e.response.status === 401)
      //  window.location = baseURL;
    } finally {

    }
  }
};

export function getProfileImageUrl(user_key) {
  return user_key == null ? `${baseURL}/api/latest/user/avatar/` : `${baseURL}/api/latest/user/${user_key}/avatar/`;
}

export function getOrganizationLogoUrl() {
  return `${baseURL}/api/latest/organization/logo/`;
}

export function requestUserNotifications() {
  
  return async (dispatch) => {
    try {
      const response = await axios.get('/notifications/api/latest/');
      dispatch({ type: RECEIVE_USER_NOTIFICATIONS, payload: response.data });
    } catch (e) {
      console.log('Error on requestUserNotifications action', e);
    } finally {

    }
  }
}

export function requestUserMenuItems() {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/latest/menu/');
      dispatch({ type: RECEIVE_USER_MENU_ITEMS, payload: response.data });
    } catch (e) {
      console.log('Error on requestUserMenuItems action', e);
    } finally {

    }
  }
}

export function requestGlobalSearch(textToSearch) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_GLOBAL_SEARCH, payload: [] });
      const response = await axios.get(`/api/latest/search/?q=${textToSearch}`);
      dispatch({ type: REQUEST_GLOBAL_SEARCH, payload: response.data });
    } catch (e) {
      console.log('Error on requestGlobalSearch action', e);
    } finally {

    }
  }
}

export function requestFilteredTasks(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null

) {
  return async (dispatch) => {
    try {
      if (on_start_dispatch_type)
        dispatch({ type: on_start_dispatch_type, payload: [] });

      const response = await axios.get(`/api/filtered-tasks/`,
        {
          params: params
        });

      dispatch({ type: on_success_dispatch_type, payload: response.data });
    } catch (e) {
      console.log('Error on requestFilteredTasks action', e);
    } finally {

    }
  }
}


export function retrieveUsers(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {
      if (on_start_dispatch_type)
        dispatch({ type: on_start_dispatch_type, payload: [] });

      const response = await axios.get('/api/latest/users/',
        {
          params
        });

      dispatch({ type: on_success_dispatch_type, payload: response.data });
    } catch (e) {
      console.log('Error on retrieveUsers')
    } finally {

    }
  }
}
export function requestFilteredWorkingOn(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {
      if (on_start_dispatch_type)
        dispatch({ type: on_start_dispatch_type, payload: [] });

      const response = await axios.get('/api/latest/filtered-working-on/',
        {
          params
        });

      dispatch({ type: on_success_dispatch_type, payload: response.data });
    } catch (e) {
      console.log('Error on requestFilteredWorkingOn action', e);
    } finally {

    }
  }
}


export function requestTimePerCategoryData(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {
      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }
      const response = await axios.get('/api/catevent/', {
        params
      });

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (e) {
      console.log('Error on requestTimePerCategoryData action', e);
    } finally {

    }
  }
}

export function requestCategoryTimelineData(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {
      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }
      const response = await axios.get('/api/timeline/', {
        params
      });

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (e) {
      console.log('Error on requestCategoryTimelineData action', e);
    } finally {

    }
  }
}

export function requestFocusTeamDashboardData(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {
      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }
      const response = await axios.get('/api/latest/focus/team-agg/', {
        params
      });

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (e) {
      console.log('Error on requestFocusTeamDashboardData action', e);
    } finally {

    }
  }
}

export function requestFocusLastEvent(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {
      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }
      const response = await axios.get('/api/latest/focus/last-event/', {
        params
      });

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (e) {
      console.log('Error on requestFocusLastEventData action', e);

      if (on_error_dispatch_type !== null)
        dispatch({ type: on_error_dispatch_type, payload: e });
    } finally {

    }
  }
}



export function createUsers(
  values,
  on_success_dispatch,
  on_success_callback,
  on_error_callback
) {
  const request = axios('/api/latest/users/', {
    method: "post",
    data: values
  }).then(
    (response) => {
      on_success_callback(response);
    }
  ).catch(
    (error) => {
      if (ERROR_WITH_MESSAGE.includes(parseInt(error.response.status)) || ERROR_WITH_MESSAGE.includes(parseInt(error.response.code))) {
        on_error_callback(error);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    }
  );
  return {
    type: on_success_dispatch,
    payload: request.data
  }
}


export function requestTimezones(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }

      const response = await axios.get('/api/latest/lookups/time-zones/');

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (error) {
      if (error !== null) {
        dispatch({ type: on_error_dispatch_type, payload: error });
      }
    } finally {

    }
  }
}

export function requestLanguages(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }

      const response = await axios.get('/api/latest/lookups/languages/');

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (error) {
      if (error !== null) {
        dispatch({ type: on_error_dispatch_type, payload: error });
      }
    } finally {

    }
  }
}

export function requestCountries(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }

      const response = await axios.get('/api/latest/lookups/countries/');

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (error) {
      if (error !== null) {
        dispatch({ type: on_error_dispatch_type, payload: error });
      }
    } finally {

    }
  }
}

export function requestDateformat(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }

      const response = await axios.get('/api/latest/lookups/date-format/');

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (error) {
      if (error !== null) {
        dispatch({ type: on_error_dispatch_type, payload: error });
      }
    } finally {

    }
  }
}

export function requestTimeformat(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }

      const response = await axios.get('/api/latest/lookups/time-format/');

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (error) {
      if (error !== null) {
        dispatch({ type: on_error_dispatch_type, payload: error });
      }
    } finally {

    }
  }
}

export function requestValidIdleTimes(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }

      const response = await axios.get('/api/latest/lookups/accepted-idle-times/');

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (error) {
      if (error !== null) {
        dispatch({ type: on_error_dispatch_type, payload: error });
      }
    } finally {

    }
  }
}

export function requestExtendedUserProfile(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null,
  expanded = "working-time"
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }

      var params = { expand: expanded };
      const response = await axios.get('/api/latest/user/', { params: params });

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (error) {
      if (error !== null) {
        dispatch({ type: on_error_dispatch_type, payload: error });
      }
    } finally {

    }
  }
}

export function requestExtendedProfileByKey(
  on_success_dispatch_type,
  user_key,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null,
  expanded = "working-time"
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }

      var params = { expand: expanded };
      const response = await axios.get('/api/latest/users/'+ user_key + '/', { params: params });

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (error) {
      if (error !== null) {
        dispatch({ type: on_error_dispatch_type, payload: error });
      }
    } finally {

    }
  }
}

export function updateUserProfile(request, on_success_callback, on_error_callback) {
  return async (dispatch) => {

    const response = await axios('/api/latest/user/', {
      method: "put",
      data: request
    }).then(
      (response) => {
        dispatch({ type: RECEIVE_PROFILE_INFO_BASIC, payload: response.data })
        if (on_success_callback !== undefined) return on_success_callback(response)
      }
    ).catch((err) => {

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    })
  }
}

export function updateWorkingHours(request, on_success_callback, on_error_callback) {
  return async (dispatch) => {
    var params = { expand: "working_hours" };
    const response = await axios('/api/latest/user/', {
      method: "put",
      data: request
    }).then(
      (response) => {
        /* ??????? RECEIVE_PROFILE_INFO_BASIC */
        dispatch({ type: RECEIVE_PROFILE_INFO_BASIC, payload: response.data })
        if (on_success_callback !== undefined) return on_success_callback(response)
      }
    ).catch((err) => {
      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    })
  }
}

export function updateUserWorkingHours(request, user_key, on_success_callback, on_error_callback) {
  return async (dispatch) => {
    var params = { expand: "working_hours" };
    const response = await axios('/api/latest/users/' + user_key + "/", {
      method: "put",
      data: request
    }).then(
      (response) => {
        if (on_success_callback !== undefined) return on_success_callback(response)
      }
    ).catch((err) => {
      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    })
  }
}

export function requestFocusAnyEvent(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {
      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }
      const response = await axios.get('/api/latest/focus/any-event/', {
        timeout: defaulRequestTimeoutms,
        params
      });

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (e) {
      console.log('Error on requestFocusAnyEvent action', e);

      if (on_error_dispatch_type !== null)
        dispatch({ type: on_error_dispatch_type, payload: e });
    } finally {

    }
  }
}


export function requestFocusUserStatusData(
  params,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {
      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }
      const response = await axios.get('/api/latest/focus/user-status/', {
        timeout: defaulRequestTimeoutms,
        params
      });

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (e) {
      console.log('Error on requestFocusUserStatus action', e);

      if (on_error_dispatch_type !== null)
        dispatch({ type: on_error_dispatch_type, payload: e });
    } finally {

    }
  }
}


export function uploadUserAvatar(params, on_success_callback, on_error_callback) {
  return async (dispatch) => {
    const response = await axios('/api/latest/user/avatar/myavatar.' + params.extension, {
      method: "put",
      headers: { 'content-type': 'image/' + params.extension },
      data: params.image,

      /*contentType:false,
      processData:false,*/
    }).then(
      (response) => {
        //dispatch({ type: RECEIVE_PROFILE_INFO_BASIC, payload: response.data })
        //if (on_success_callback !== undefined) return on_success_callback(response)
        on_success_callback({ response: response });
      }
    ).catch((err) => {

      if (!err.response) {
        on_error_callback({ response: { status: 500 } });
        return;
      }

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }

      /*var json_response = null;
      if (err.response !== undefined && err.response.status >= 400) {
        json_response = JSON.parse(err.request.response)
      }
      on_error_callback({
        response: json_response
      });*/
    })
  }
}

export function requestOrganizationLogo(
  on_success_callback,
  on_error_callback
) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/latest/organization/logo/?url=true');

      on_success_callback(response);

    } catch (err) {

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }

    } finally {

    }
  }
}

export function uploadOrganizationLogo(params, on_success_callback, on_error_callback) {
  return async (dispatch) => {
    const response = await axios('/api/latest/organization/logo/logo.' + params.extension, {
      method: "put",
      headers: { 'content-type': 'image/' + params.extension },
      data: params.image,

      /*contentType:false,
      processData:false,*/
    }).then(
      (response) => {
        //dispatch({ type: RECEIVE_PROFILE_INFO_BASIC, payload: response.data })
        //if (on_success_callback !== undefined) return on_success_callback(response)
        on_success_callback({ response: response });
      }
    ).catch((err) => {

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }

    })
  }
}

export function updatePassword(params, on_success_callback, on_error_callback) {
  return async (dispatch) => {
    const response = await axios('/api/latest/user/set-password/', {
      method: "post",
      data: params,
    }).then(
      (response) => {
        on_success_callback(response);
      }
    ).catch((err) => {

      if(!err){
        return on_error_callback({ response: { status: 500 } });
      }

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    })
  }
}

export function updateEmail(params, on_success_callback, on_error_callback) {
  return async (dispatch) => {
    const response = await axios('/api/latest/user/request-update-email/', {
      method: "post",
      data: params,
    }).then(
      (response) => {
        on_success_callback(response);
      }
    ).catch((err) => {

      if(!err){
        return on_error_callback({ response: { status: 500 } });
      }

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    })
  }
}

export function logoutFromDevices(
  on_success_callback,
  on_error_callback) {
  return async (dispatch) => {

    const response = await axios('/api/latest/user/logout-from-devices/', {
      method: "post",
    }).then(
      (response) => {
        on_success_callback(response);
      }
    ).catch((err) => {
      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    })
  }
}


export function requestUserNotificationSettings(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }

      const response = await axios.get('/api/latest/user/notification-settings/');

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (error) {
      if (error !== null) {
        dispatch({ type: on_error_dispatch_type, payload: error });
      }
    } finally {

    }
  }
}

export function updateUserNotificationSettings(
  params,
  on_success_callback,
  on_error_callback) {

  return async (dispatch) => {
    const response = await axios('/api/latest/user/notification-settings/', {
      method: "put",
      data: params

    }).then(
      (response) => {
        on_success_callback(response);
      }

    ).catch((err) => {
      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    })

  }
}

export function requestOrganizationNotifications(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }

      const response = await axios.get('/api/latest/organization/notification-settings/');

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (error) {
      if (error !== null) {
        dispatch({ type: on_error_dispatch_type, payload: error });
      }
    } finally {

    }
  }
}

export function updateOrganizationNotifications(
  params,
  on_success_callback,
  on_error_callback
) {

  return async (dispatch) => {
    const response = await axios('/api/latest/organization/notification-settings/', {
      method: "put",
      data: params

    }).then(
      (response) => {
        on_success_callback(response);
      }

    ).catch((err) => {
      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    })

  }
}

export function requestOrganizationInfo(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }

      const response = await axios.get('/api/latest/organization/');

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (error) {
      if (error !== null) {
        dispatch({ type: on_error_dispatch_type, payload: error });
      }
    } finally {

    }
  }
}

export function updateOrganizationInfo(
  params,
  on_success_callback,
  on_error_callback
) {

  return async (dispatch) => {
    const response = await axios('/api/latest/organization/', {
      method: "put",
      data: params

    }).then(
      (response) => {
        on_success_callback(response);
      }

    ).catch((err) => {

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    })

  }
}

export function requestOrgDataDeletionToken(
  on_success_callback,
  on_error_callback
) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/latest/organization/delete-all-data/');

      on_success_callback(response);

    } catch (err) {
      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }

    } finally {

    }
  }
}

export function confirmOrgDataDeletion(
  params,
  on_success_callback,
  on_error_callback
) {

  return async (dispatch) => {
    try {
      const response = await axios('/api/latest/organization/delete-all-data/', {
        method: "post",
        data: params

      });
      on_success_callback(response);
    } catch (err) {

      if (ERROR_WITH_MESSAGE.includes(parseInt(err.response.status)) || ERROR_WITH_MESSAGE.includes(parseInt(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    }


  }
}

export function requestOrgAccountDeletionToken(
  on_success_callback,
  on_error_callback
) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/latest/organization/delete/');

      on_success_callback(response);

    } catch (err) {
      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }

    } finally {

    }
  }
}

export function confirmOrgAccountDeletion(
  params,
  on_success_callback,
  on_error_callback
) {

  return async (dispatch) => {
    try {
      const response = await axios('/api/latest/organization/delete/', {
        method: "post",
        data: params
      });

      on_success_callback(response);
    } catch (err) {

      if (err.response == null) {
        on_error_callback({ response: { status: 500 } });
        return;
      }

      if (ERROR_WITH_MESSAGE.includes(parseInt(err.response.status)) || ERROR_WITH_MESSAGE.includes(parseInt(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    }

  }
}

/*export function getProfileAvatarUrl(user_key){
  return user_key == null? `${baseURL}/api/latest/user/avatar/`:`${baseURL}/api/latest/user/${user_key}/avatar/`;
}*/

export function getLogoutUrl(callback) {
  return async () => {
    const imgUrl = baseURL + '/accounts/logout';
    callback(imgUrl);
  }

}

export function deleteAvatar(
  on_success_callback,
  on_error_callback
) {

  return async (dispatch) => {
    try {
      const response = await axios('/api/latest/user/avatar/', {
        method: "delete",
      });

      on_success_callback(response);

    } catch (err) {

      if (err.response == null) {
        on_error_callback({ response: { status: 500 } });
        return;
      }

      if (ERROR_WITH_MESSAGE.includes(parseInt(err.response.status)) || ERROR_WITH_MESSAGE.includes(parseInt(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    }

  }
}


export function deleteOrganizationLogo(
  on_success_callback,
  on_error_callback
) {

  return async (dispatch) => {
    try {
      const response = await axios('/api/latest/organization/logo/', {
        method: "delete",
      });

      on_success_callback(response);

    } catch (err) {

      if (err.response == null) {
        on_error_callback({ response: { status: 500 } });
        return;
      }

      if (ERROR_WITH_MESSAGE.includes(parseInt(err.response.status)) || ERROR_WITH_MESSAGE.includes(parseInt(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    }

  }
}

export function refreshProfileImageTimeStamp() {
  return async (dispatch) => {
    dispatch({ type: REFRESH_PROFILE_IMG_TIMESTAMP, payload: null });

  }
}

export function refreshOrganizationLogoTimeStamp() {
  return async (dispatch) => {
    dispatch({ type: REFRESH_ORGANIZATION_LOGO_TIMESTAMP, payload: null });

  }
}

export function requestRolesList(
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null
) {
  return async (dispatch) => {
    try {

      if (on_start_dispatch_type) {
        dispatch({ type: on_start_dispatch_type, payload: [] });
      }

      const response = await axios.get('/api/latest/roles/');

      dispatch({ type: on_success_dispatch_type, payload: response.data });

    } catch (error) {
      if (error !== null) {
        dispatch({ type: on_error_dispatch_type, payload: error });
      }
    } finally {

    }
  }
}

export function requestRoleByKey (
  role_key,
  on_success_callback,
  on_error_callback
) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/latest/roles/'+role_key+'/');

      on_success_callback(response);

    } catch (err) {
      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }

    } finally {

    }
  }
}

export function createNewRole (
  params,
  on_success_callback,
  on_error_callback
) {

  return async (dispatch) => {
    try {
      const response = await axios('/api/latest/roles/', {
        method: "post",
        data: params
      });

      on_success_callback(response);
    } catch (err) {

      if (err.response == null) {
        on_error_callback({ response: { status: 500 } });
        return;
      }

      if (ERROR_WITH_MESSAGE.includes(parseInt(err.response.status)) || ERROR_WITH_MESSAGE.includes(parseInt(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    }

  }
}

export function updateRole(
  role_key,
  params,
  on_success_callback,
  on_error_callback
) {
  return async (dispatch) => {
    const response = await axios('/api/latest/roles/'+role_key+'/', {
      method: "put",
      data: params

    }).then(
      (response) => {
        on_success_callback(response);
      }

    ).catch((err) => {

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    })

  }
}

export function deleteRole(
  role_key,
  on_success_callback,
  on_error_callback
) {

  return async (dispatch) => {
    try {
      const response = await axios('/api/latest/roles/'+role_key+'/', {
        method: "delete",
      });

      on_success_callback(response);

    } catch (err) {

      if (err.response == null) {
        on_error_callback({ response: { status: 500 } });
        return;
      }

      if (ERROR_WITH_MESSAGE.includes(parseInt(err.response.status)) || ERROR_WITH_MESSAGE.includes(parseInt(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    }

  }
}

export function updateOtherUserProfile(request, user_key, on_success_callback, on_error_callback) {
  return async (dispatch) => {

    const response = await axios('/api/latest/users/'+user_key+'/', {
      method: "put",
      data: request
    }).then(
      (response) => {
        dispatch({ type: RECEIVE_PROFILE_INFO_BASIC, payload: response.data })
        if (on_success_callback !== undefined) return on_success_callback(response)
      }
    ).catch((err) => {
      if(!err.response){
        return on_error_callback({ response: { status: 500 } });
      }

      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    })
  }
}

export function getUserExtendedProfile(params, user_key, on_success_callback, on_error_callback) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/latest/users/'+user_key+'/', {
        params: params
      });

      on_success_callback(response);

    } catch (err) {
      if (err.response && (ERROR_WITH_MESSAGE.includes(err.response.status) || ERROR_WITH_MESSAGE.includes(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }

    } finally {

    }
  }
}

export function deleteUserByKey (
  user_key,
  on_success_callback,
  on_error_callback
) {

  return async (dispatch) => {
    try {
      const response = await axios('/api/latest/users/'+user_key+'/', {
        method: "delete",
      });

      on_success_callback(response);

    } catch (err) {

      if (err.response == null) {
        on_error_callback({ response: { status: 500 } });
        return;
      }

      if (ERROR_WITH_MESSAGE.includes(parseInt(err.response.status)) || ERROR_WITH_MESSAGE.includes(parseInt(err.response.code))) {
        on_error_callback(err);
      } else {
        on_error_callback({ response: { status: 500 } });
      }
    }

  }
}