import {
    RETRIEVE_ORG_INFO_COMPLETED,
    RETRIEVE_ORG_INFO_ERROR
} from '../actions/actions';

const initialState = {
    "company_key":"",
    "name":"",
    "identifier":"",
    "address":"",
    "language_code":"es",
    "active_plan_id":"",
    "week_starts_monday":true,
    "work_time_start_hour": "8",
    "work_time_end_hour": "23",
    "allow_tasks_without_project":true,
    "active":true
 }


const fiOrganizationInfo = (state = initialState, action) => {
    
    switch (action.type) {
        case RETRIEVE_ORG_INFO_COMPLETED:
            if (action.error) {
                return { ...state, name: 'error' };
            }
            else
                return {
                    ...state,
                    company_key: action.payload.company_key,
                    name: action.payload.name,
                    identifier: action.payload.identifier,
                    address: action.payload.address,
                    language_code: action.payload.language_code,
                    active_plan_id: action.payload.active_plan_id,
                    week_starts_monday: action.payload.week_starts_monday,
                    work_time_start_hour: action.payload.work_time_start_hour,
                    work_time_end_hour: action.payload.work_time_end_hour,
                    allow_tasks_without_project: action.payload.allow_tasks_without_project,
                    active: action.payload.active
                };


        case RETRIEVE_ORG_INFO_ERROR:
            return { ...state, errorMessage: action.payload }

       
        default:
            return state;
    }
}

export default fiOrganizationInfo;
