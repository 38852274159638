import {

    RETRIEVE_BILLING_CARDS_COMPLETED,
    RETRIEVE_BILLING_CARDS_ERROR,

    RETRIEVE_BILLING_INVOICES_COMPLETED,
    RETRIEVE_BILLING_INVOICES_ERROR,

    RETRIEVE_BILLING_SUBSCRIPTION_COMPLETED,
    RETRIEVE_BILLING_SUBSCRIPTION_ERROR

} from '../actions/actions';

const initialState = {
    invoices: null,
    cards: null,
    subscription: null,

    invoicesError: null,
    cardsError: null,
    subscriptionError: null
}

const fiBillingInfo = (state = initialState, action) => {

    switch (action.type) {

        case RETRIEVE_BILLING_CARDS_COMPLETED:
            if (action.error) return { ...state, cards: null};
            else return {...state, cards: action.payload};

        case RETRIEVE_BILLING_CARDS_ERROR:
            return { ...state, cardsError: action.payload};

        case RETRIEVE_BILLING_INVOICES_COMPLETED:
            if (action.error) return { ...state, invoices: null};
            else return {...state, invoices: action.payload};

        case RETRIEVE_BILLING_INVOICES_ERROR:
            return { ...state, invoicesError: action.payload};

        case RETRIEVE_BILLING_SUBSCRIPTION_COMPLETED:
            if (action.error) return { ...state, subscription: null};
            else return {...state, subscription: action.payload};

        case RETRIEVE_BILLING_SUBSCRIPTION_ERROR:
            return { ...state, subscriptionError: action.payload};

        default:
            return state;
    }
}

export default fiBillingInfo;
