import React, { Component } from 'react';
import { translate, Trans } from 'react-i18next';
import { Row, Col, Table } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as moment from 'moment';



import {
  REQUEST_DASHBOARD_WORKING_ON,
  requestFilteredWorkingOn
} from '../../store/actions/focoosin.actions.js';





class TeamWorkingOnList extends Component {

  constructor(state)
      {
        super(state);
      }

  componentDidUpdate(prevProps) {
    if (this.props.projectKey != prevProps.projectKey) {
      this.pullWorkingOnData();
    }
  }
  pullWorkingOnData() {
    let pullData = false;
    // Retrieve Projects
    console.log("TEAMVIEW", this.props);
    let filterParams = {};
    if (this.props.projectKey)
    {
      filterParams['project-key'] = this.props.projectKey;
      pullData = true;
    }
    if (! ("projectKey" in this.props)) pullData = true;

    if (pullData) {
    this.props.requestFilteredWorkingOn(
      filterParams,
      REQUEST_DASHBOARD_WORKING_ON
    );
  }
  }
  componentWillMount()
      {
        this.pullWorkingOnData();
      }

  render ()
    {
          const today = new Date();

          return(
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                  <th>User</th>
                                  <th>Task Key</th>
                                  <th>Activity</th>
                                  <th>Task Name</th>
                                  <th>Type</th>
                                  <th>Project</th>
                                  <th>Due date</th>
                                </tr>
                            </thead>
                            <tbody>
                              { this.props.teamWorkingOn.map((wo_item) =>
                              <tr key={wo_item.user}>
                                <td>
                                    <a href="">{ wo_item.display_full_name }</a>
                                </td>
                                <td>{ wo_item.task_key }
                                { wo_item.task_is_active && <div className="circle circle-lg bg-success" data-toggle="tooltip" data-title="normal"></div> }
                                </td>
                                <td>
                                    { wo_item.task_active_end && <div><Trans i18nKey='common.PAUSED'/> { moment(wo_item.task_active_end).fromNow() }</div>}
                                    { !wo_item.task_active_end && <div><Trans i18nKey='common.ACTIVE'/> ({moment(wo_item.task_active_start).fromNow() })</div>}
                                </td>
                                <td className="text-nowrap">
                                    <small>{ wo_item.task_title}</small>
                                </td>
                                <td>
                                    <div className="badge bg-gray-lighter">{wo_item.task_type_name}</div>
                                </td>
                                <td className="text-nowrap">
                                    <small><Link to={`projects/${wo_item.project_key}`}>{wo_item.project_name}</Link></small>
                                </td>
                                <td>{ moment(wo_item.task_due_date).format(this.props.userProfileSettings.datetimeFormat) }</td>
                                <td>

                                </td>
                              </tr>

                            )}
                            </tbody>
                        </Table>


    )
  }
}

  function mapStateToProps(state)
  {
    return {
      teamWorkingOn:[], //state.tasksReducer.teamWorkingOn,
      userProfileSettings: state.sessionUserSettings,
    }
  }
  export default compose (
    translate('translations'),
    connect(mapStateToProps, {requestFilteredWorkingOn})
  )(TeamWorkingOnList);