import {
    RETRIEVE_USER_NOTIFICATIONSETTINGS_COMPLETED,
    RETRIEVE_USER_NOTIFICATIONSETTINGS_ERROR
} from '../actions/actions';

const initialState = {
    "weekly_email": null,
    "monthly_news_letter":null,
    "task_edition":3,
    "task_assignment":3,
    "task_comment_posted":3,
    "project_assignment":3,
    "project_edited":3,
    "project_comment_posted":3,
    "same_task":2,
    "same_task_threshold":240,
    "no_task_alert":2,
    "no_task_threshold":60,
    "edit_permission": null
 }

const fiNotificationSettings = (state = initialState, action) => {
    
    switch (action.type) {
        case RETRIEVE_USER_NOTIFICATIONSETTINGS_COMPLETED:
            if (action.error) {
                return { ...state, monthly_news_letter: false };
            }
            else
                return {
                    ...state,
                    "weekly_email": action.payload.weekly_email,
                    "monthly_news_letter": action.payload.monthly_news_letter,
                    "task_edition": action.payload.task_edition,
                    "task_assignment": action.payload.task_assignment,
                    "task_comment_posted": action.payload.task_comment_posted,
                    "project_assignment": action.payload.project_assignment,
                    "project_edited": action.payload.project_edited,
                    "project_comment_posted": action.payload.project_comment_posted,
                    "same_task": action.payload.same_task,
                    "same_task_threshold": action.payload.same_task_threshold,
                    "no_task_alert": action.payload.no_task_alert,
                    "no_task_threshold": action.payload.no_task_threshold,
                    "edit_permission":  action.payload.edit_permission
                };


        case RETRIEVE_USER_NOTIFICATIONSETTINGS_ERROR:
            return { ...state, errorMessage: action.payload }

       
        default:
            return state;
    }
}

export default fiNotificationSettings;
