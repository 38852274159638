import {
    RETRIEVE_TIMEZONES_COMPLETED,
    RETRIEVE_TIMEZONES_ERROR,

    RETRIEVE_LANGUAGES_COMPLETED,
    RETRIEVE_LANGUAGES_ERROR,

    RETRIEVE_COUNTRIES_COMPLETED,
    RETRIEVE_COUNTRIES_ERROR,

    RETRIEVE_TIMEFORMAT_COMPLETED,
    RETRIEVE_TIMEFORMAT_ERROR,

    RETRIEVE_DATEFORMAT_COMPLETED,
    RETRIEVE_DATEFORMAT_ERROR,

    RETRIEVE_VALIDTIMES_COMPLETED,
    RETRIEVE_VALIDTIMES_ERROR,

    REQUEST_CONNECTION_FAILED,
    REQUEST_UNAUTHENTICATED
} from '../actions/actions';

const initialState = {
    timezones: [],
    dateformat: [],
    countries: [],
    timeformat: [],
    languages: [],
    idleTimes: []

}

const fiStaticFormData = (state = initialState, action) => {

    switch (action.type) {

        case RETRIEVE_TIMEZONES_COMPLETED:
            if (action.error) {
                return { ...state, timezones: ["ERROR"] };
            }
            else
                return {
                    ...state,
                    timezones: action.payload,
                };

        case RETRIEVE_LANGUAGES_COMPLETED:
            if (action.error) {
                return { ...state, languages:["ERROR"] };
            }
            else
                return {
                    ...state,
                    languages: action.payload,
                };

        case RETRIEVE_TIMEFORMAT_COMPLETED:
            if (action.error) {
                return { ...state, timeformat:["ERROR"] };
            }
            else
                return {
                    ...state,
                    timeformat: action.payload,
                };

        case RETRIEVE_COUNTRIES_COMPLETED:
            if (action.error) {
                return { ...state, countries:["ERROR"] };
            }
            else
                return {
                    ...state,
                    countries: action.payload,
                };

        case RETRIEVE_DATEFORMAT_COMPLETED:
        if (action.error) {
            return { ...state, dateformat:["ERROR"] };
        }
        else
            return {
                ...state,
                dateformat: action.payload,
            };

        case RETRIEVE_VALIDTIMES_COMPLETED:
            if (action.error) {
                return { ...state, idleTimes:["ERROR"] };
            }
            else
                return {
                    ...state,
                    idleTimes: action.payload,
                };

        case RETRIEVE_VALIDTIMES_ERROR:
            return { ...state, errorMessage: action.payload }

        case RETRIEVE_TIMEZONES_ERROR:
            return { ...state, errorMessage: action.payload }

        case RETRIEVE_COUNTRIES_ERROR:
            return { ...state, errorMessage: action.payload }

        case RETRIEVE_TIMEFORMAT_ERROR:
            return { ...state, errorMessage: action.payload }

        case RETRIEVE_LANGUAGES_ERROR:
            return { ...state, errorMessage: action.payload }

        case RETRIEVE_DATEFORMAT_ERROR:
            return { ...state, errorMessage: action.payload }




        case REQUEST_CONNECTION_FAILED:
            return {
                ...state,
                globalConnectionError: action.payload
            }
        case REQUEST_UNAUTHENTICATED:
            return {
                ...state,
                globalAuthenticated: false
            }
        default:
            return state;
    }
}

export default fiStaticFormData;
