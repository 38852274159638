import React, { Component } from 'react';

import Kanban from './Kaban';

class ProjectCalenbanView extends Component
{
  render()
  {
    return (
      <Kanban></Kanban>
    )
  }
}

export default ProjectCalenbanView;
