import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import WebNotifications from '../../ficomponents/notifications/WebNotifications';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import { baseURL } from '../../store/actions/focoosin.actions.js';
import {RETRIEVE_VIEW_SELECTION_COMPLETED} from '../../store/actions/focoosin.uiselection.actions.js';
import ToggleFullscreen from '../Common/ToggleFullscreen';
import HeaderRun from './Header.run'
import 'moment/locale/es';
import * as moment from 'moment';

import ProfilePicture from '../../ficomponents/Common/ProfilePicture';
import OrganizationLogo from '../../ficomponents/Common/OrganizationLogo';


class Header extends Component {

    componentDidMount() {
        HeaderRun();
        this.props.actions.requestUserMenuItems();
        this.props.actions.requestProfileInfoBasic();
        this.props.actions.requestUserNotifications();

        // this.props.actions.getViewSelection(
        //     null,
        //     'panel.team',
        //     RETRIEVE_VIEW_SELECTION_COMPLETED,
        //     null,
        //     null,
        //     null
        // );

        this.changeLanguage(this.props.languageCode);
        moment.locale(this.props.languageCode);
    }

    componentDidUpdate(prevProps) {

        if (this.props.languageCode !== prevProps.languageCode) {
            this.changeLanguage(this.props.languageCode);
            moment.locale(this.props.languageCode);
        }

    }

    changeLanguage = lng => {
        console.log('Setting UI language to ', lng);

        if (lng != null)
            this.props.i18n.changeLanguage(lng);
    }


    toggleUserblock = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('showUserBlock');
    }

    toggleOffsidebar = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('offsidebarOpen');
    }

    toggleCollapsed = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('isCollapsed');
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    render() {
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo">
                                {this.props.sessionUserKey ?
                                    <OrganizationLogo
                                        key={this.props.sessionUserKey} 
                                        user_key={this.props.sessionUserKey} 
                                        size="small"
                                        isHeader={true}
                                        OrganizationLogoTimeStamp={this.props.OrganizationLogoTimeStamp}
                                    />
                                    :
                                    <img className="img-fluid" src="fiimg/focoosin_txt_logo_header.png" alt="Focoosin Logo" />
                                }
                            </div>
                            <div className="brand-logo-collapsed">
                            {this.props.sessionUserKey ?
                                    <OrganizationLogo 
                                        key={this.props.sessionUserKey} 
                                        user_key={this.props.sessionUserKey} 
                                        size="small"
                                        isHeader={true}
                                        OrganizationLogoTimeStamp={this.props.OrganizationLogoTimeStamp}
                                    />
                                    :
                                    <img className="img-fluid" src="fiimg/focoosin_logo_single.png" alt="Foocoosin Logo" />
                                }
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */}

                    { /* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={this.toggleCollapsed}>
                                <em className="fas fa-bars"></em>
                            </a>
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <a href="" className="nav-link sidebar-toggle d-md-none" onClick={this.toggleAside}>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>
                    </ul>
                    { /* END Left navbar */}
                    { /* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        { /* Search icon */}
                        {/*                         <li className="nav-item">
                            <a className="nav-link" href="" data-search-open="">
                                <em className="icon-magnifier"></em>
                            </a>
                        </li>
 */}                        { /* START Alert menu */}
                        <WebNotifications />
                        { /* END Alert menu */}
                        { /* START Offsidebar button */ }
                        {/* <li className="nav-item">
                            <a className="nav-link" href="" onClick={this.toggleOffsidebar}>
                                <em className="icon-notebook"></em>
                            </a>
                        </li> */}
                        { /* END Offsidebar menu */ }

                        { /* START User avatar toggle */}
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                {!this.props.sessionUserKey && <em className="icon-user"></em>}
                                {this.props.sessionUserKey && <ProfilePicture key={this.props.sessionUserKey}  display_user_name={this.props.sessionUserFullName} size="small" profileImageTimeStamp={this.props.profileImageTimeStamp} />}
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem tag={Link} to="/settings/profile"><Trans i18nKey='header.PROFILESETTINGS'></Trans></DropdownItem>
                                {this.props.menuProfile.map(m => <DropdownItem key={m.name} tag={Link} to={m.path}><Trans i18nKey={m.translate}>{m.name}</Trans></DropdownItem>)}
                                <DropdownItem divider />
                                <DropdownItem href={`${baseURL}/accounts/logout`}><Trans i18nKey='header.LOGOUT'>Logout</Trans></DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        { /* END User avatar toggle */}
                    </ul>
                    <form className="navbar-form" role="search" action="search.html">
                        <div className="form-group">
                            <input className="form-control" type="text" placeholder="Type and hit enter ..." />
                            <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                        </div>
                        <button className="d-none" type="submit">Submit</button>
                    </form>
                    { /* END Search form */}
                </nav>
                { /* END Top Navbar */}
            </header>
        );
    }

}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => (
    {
        settings: state.settings,
        sessionUserKey: "None",
        profileImageTimeStamp: state.sessionUserSettings.profileImageTimeStamp,
        OrganizationLogoTimeStamp: state.sessionUserSettings.OrganizationLogoTimeStamp,
        sessionUserFullName: state.sessionUserSettings.fullName,
        languageCode: state.sessionUserSettings.languageCode,
        menuProfile: state.sessionUserSettings.menuProfile
    })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withNamespaces()

)(Header);