import { RECEIVE_USER_NOTIFICATIONS } from '../actions/actions';

const initialState = {
    notifications:[],
    total:0
}

const finotificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_USER_NOTIFICATIONS:
        return {...state,
            notifications:action.payload.notifications,
            total:action.payload.total
          }
        default:
            return state;
    }
}

export default finotificationsReducer;
