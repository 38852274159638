import axios from 'axios';
export * from './focoosin.actions.js';

export const RETRIEVE_VIEW_SELECTION_COMPLETED = 'RETRIEVE_VIEW_SELECTION_COMPLETED';
export const RETRIEVE_VIEW_SELECTION_ERROR = 'RETRIEVE_VIEW_SELECTION_ERROR';

export const UPDATE_FOCUS_INDIVIDUAL_DATE = 'UPDATE_FOCUS_INDIVIDUAL_DATE';
export const UPDATE_FOCUS_INDIVIDUAL_USER = 'UPDATE_FOCUS_INDIVIDUAL_USER';
export const UPDATE_FOCUS_TEAM_DATE_RANGE = 'UPDATE_FOCUS_TEAM_DATE_RANGE';
export const UPDATE_FOCUS_TEAM_SHOW_CATEGORIES = 'UPDATE_FOCUS_TEAM_SHOW_CATEGORIES';
export const UPDATE_SHOW_CATEGORIES_TEAM_VIEW = 'UPDATE_SHOW_CATEGORIES_TEAM_VIEW';
export const UPDATE_SELECTED_VIEW_AND_FILTERS = 'UPDATE_SELECTED_VIEW_AND_FILTERS';

const ERROR_WITH_MESSAGE = [400, 401, 402, 403];


export function setViewSelection(
  user_key,
  section,
  data,
  on_success_dispatch_type,
  on_success_callback,
  on_error_dispatch_type,

) {
  

   return async (dispatch) => {
    
    dispatch({ type: on_success_dispatch_type, payload: data }); 
    
  //   const url = user_key? `api/latest/user/${user_key}/ui-settings/?section=${section}/`:`api/latest/user/ui-settings/?section=${section}/`;
    
    

  //   const response = await axios(url,
  //     {
  //       method: "post",
  //       data: data,
  //     }).then(
  //       (response) => {
  //         dispatch({ type: on_success_dispatch_type, payload: response.data });
  //         if (on_success_callback != null)
  //           on_success_callback(response);
  //       }
  //     ).catch(
  //       (error) => {
  //         dispatch({ type: on_error_dispatch_type, payload: null });
  //       }
  //     );

  }
}


export function getViewSelection(
  user_key,
  section,
  on_success_dispatch_type,
  on_start_dispatch_type = null,
  on_error_dispatch_type = null,
  on_success_callback = null
) {
  return async (dispatch) => {
    if (on_start_dispatch_type) {
      dispatch({ type: on_start_dispatch_type, payload: [] });
    }

    const url = user_key? `api/latest/user/${user_key}/ui-settings/?section=${section}/`:`api/latest/user/ui-settings/?section=${section}/`;

    const response = await axios.get(url, {
      'section': section
    }).then(
      (response) => {
        dispatch({ type: on_success_dispatch_type, payload: response.data });
        if (on_success_callback !== null) {
          on_success_callback(response);
        }
      }
    ).catch(e => {
      console.log('Error on getViewSelection action', e);
      if (on_error_dispatch_type != null)
        dispatch({ type: on_error_dispatch_type, payload: null });
    })
  }
}

export function monitorUpdateUserSelection(
  action_type,
  action_payload
) {

  return async (dispatch) => {
    dispatch({ type: action_type, payload: action_payload })
  }
}
