import React, { Component } from 'react';
import axios from 'axios';

import ContentWrapper from '../../components/Layout/ContentWrapper';
import ProjectCalenbanView from './ProjectCalenbanView';
import ProjectOverviewView from './ProjectOverviewView';


import { Route, Link, Switch, Redirect} from 'react-router-dom';
import {
    Row,
    Col,
    Progress,
    Tooltip,
    Button,
    Alert,
    Input,
    FormFeedback,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav, Navbar, NavbarToggler, Collapse, UncontrolledDropdown, NavItem, NavLink, NavbarBrand
} from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    Field, reduxForm, getFormMeta, formValueSelector, SubmissionError, reset, initialize
} from 'redux-form';
import { withNamespaces, translate, Trans } from 'react-i18next';
import ProfilePicture from '../Common/ProfilePicture';
import Select from 'react-select';
import swal from 'sweetalert';
import {
    getProfileImageUrl,
    addProjectAsFavorite,
    removeProjectAsFavorite,
    requestUserMenuItems,
    deleteProject,
    requestProjects,
    updateProject,
    retrieveUsers,
    REQUEST_PROJECTS_COMPLETED,
    RQST_USERS_AVAILALBE_FOR_PROJECT_DONE
} from '../../store/actions/focoosin.projects.actions.js';
import BSTooltip from '../Common/BSTooltip';

import Scrollable from '../../components/Common/Scrollable'

// CSS Loaders
import 'loaders.css/loaders.css';
import 'spinkit/css/spinkit.css';


function renderReactSelectField(field) {
    return (
        <Select
            {...field}
            onChange={(value) => field.input.onChange(value)}
            options={field.options}
            closeMenuOnSelect={field.closeMenuOnSelect}
            isMulti
            value={field.input.value}
        />
    )
}

function renderField(field) {
    // id='userName' name='userName' type='text' className='required' />
    // Alternative to not use field.meta.touched and field.meta.error
    const { meta: { touched, error } } = field;
    const className = `form-group ${touched && error ? 'has-danger' : ''}`;
    const isInvalid = touched !== undefined && touched && error !== undefined;


    return (
        <div className={className}>
            <Input
                className='form-control'
                type="text"
                invalid={isInvalid}
                maxLength={field.maxLength}
                {...field.input}
            />
            <FormFeedback>
                {touched ? error : ''}
            </FormFeedback>
        </div>
    )
}


const fieldsMapping = {
    'identifier': 'identifier'
}

class ProjectEdit extends Component {

    constructor(props) {
        super(props)

        this.state = {
            saving: false,
            membersErrorMessage: '',
            formErrorMessage: '',
            editingAll: false,
            selectedOptionMulti: [],
            dropdownOpen: false
            // editingTeamMembersValue: [...this.props.projectMembers],
            // editingNameValue: this.props.project !== null ? this.props.project.name : null,
            // editingIdentifierValue: this.props.project !== null ? this.props.project.identifier : null,
            // editingDescriptionValue: this.props.project !== null ? this.props.project.description : null
        }

    }

    handleChangeSelectMulti = (selectedOptionMulti) => {
        this.setState({ selectedOptionMulti });
    }

    handleUsersSelectedChanged = (selected_obj) => {
        this.setState({ editingTeamMembersValue: selected_obj });
    }

    routeActive(paths) {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => this.props.location.pathname.indexOf(p) > -1)
    }

    validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;
        this.setState({
            [input.name]: value
        });

    }
    confirmDeleteTeam = () => {
        const { t } = this.props;
        swal({
            title: t('teams.edit.CONFIRM_DELETE_AREYOUSURE'),
            text: t('teams.edit.CONFIRM_DELETE_DETAILS'),
            icon: "warning",
            buttons: [t('teams.edit.CONFIRM_DELETE_BTN_NO'), t('teams.edit.CONFIRM_DELETE_BTN_YES')],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    this.setState({ saving: true, membersErrorMessage: '' });
                    this.props.deleteTeam(
                        this.props.project.team_key,
                        () => {
                            this.setState({ saving: false, membersErrorMessage: '' });
                            this.reloadTeams();
                            swal(t("teams.edit.CONFIRM_DELETION_SUCCESS"), {
                                icon: "success",
                            });
                            this.props.history.push('/teams/');
                        },
                        (response) => {
                            this.setState({ saving: false, formErrorMessage: response.response.user_message });
                        }

                    )


                }
            });
    }

    addAsFavorite = () => {
        this.setState({ saving: true });
        this.props.addProjectAsFavorite(
            { team_key: this.props.project.team_key },
            null,
            null,
            null,
            () => {
                this.reloadTeams();
                this.setState({ saving: false });
            },
            () => {
                this.setState({ saving: false });
            }
        )
    }
    removeAsFavorite = () => {
        this.setState({ saving: true });
        this.props.removeTeamAsFavorite(
            { team_key: this.props.project.team_key },
            null,
            null,
            null,
            () => {
                this.reloadTeams();
                this.setState({ saving: false });
            },
            () => {
                this.setState({ saving: false });
            }
        )
    }
    reloadTeams = (navigateTo = null) => {
        this.props.requestUserMenuItems();

        this.props.requestTeams(
            {},
            REQUEST_PROJECTS_COMPLETED,
            null,
            null,
            () => {
                if (navigateTo != null) {
                    this.props.history.push(navigateTo);
                }
            }
        )
    }

    setViewMode = (editing) => {
        const { team, teamMembers } = this.props;
        this.setState(
            {
                saving: false,
                membersErrorMessage: '',
                formErrorMessage: '',
                editingAll: editing,

                // editingTeamMembersValue: [...this.props.projectMembers],
                // editingNameValue: this.props.project.name,
                // editingIdentifierValue: this.props.project.identifier,
                // editingDescriptionValue: this.props.project.description
            }
        )
        if (editing) {
            this.props.reset();
            this.props.dispatch(initialize(
                'ProjectEditForm',
                {
                    name: team.name,
                    identifier: team.identifier,
                    description: team.description,
                    members: teamMembers
                },
            ));

        }
    }
    toggleProjectMenu = () => this.setState({
        dropdownOpen: !this.state.dropdownOpen
    })

    onSubmit(values) {

        // help from: https://github.com/erikras/redux-form/issues/2269

        // Map Members


        let newValues = {};
        newValues["name"] = values["name"];
        newValues["description"] = values["description"];
        newValues["identifier"] = values["identifier"];
        if (values['members'] != null) {
            newValues["members"] = values["members"].map(m => m.value);
        }
        const changedTeamCode = values["identifier"] != this.props.project.identifier;
        this.setState({ saving: true });
        // throw new SubmissionError({ name: 'User does not exist', _error: 'Login failed!' });
        return axios(`/api/latest/teams/${this.props.project.team_key}/`, {
            method: "put",
            data: newValues,
        }).then(
            (response) => {
                this.setState({ saving: false, editingAll: false, loading: false });
                this.reloadProjects(changedTeamCode ? '/teams/' + newValues["identifier"] + '/' : null);


            }
        ).catch(
            (error) => {
                this.setState({ loading: false });
                this.setState({ saving: false, formErrorMessage: error.response.user_message });

                var fieldWithError = error.response.data.field;
                var errorMessage = error.response.data.user_message;
                if (fieldWithError != null) {

                    if (fieldWithError in fieldsMapping) {
                        fieldWithError = fieldsMapping[fieldWithError];
                    }
                    var fieldErrors = { _error: errorMessage };
                    fieldErrors[fieldWithError] = errorMessage;
                    throw new SubmissionError(fieldErrors);
                }
                else {
                    throw new SubmissionError({ _error: errorMessage });
                }


            }
        );


    }

    componentDidMount() {
        this.props.retrieveUsers(
            {},
            RQST_USERS_AVAILALBE_FOR_PROJECT_DONE
        )
    }
    render() {
        const { handleSubmit, project, pristine } = this.props;
        const project_code = this.props.match.params.projectcode;
        return (
            <ContentWrapper>
                <div className="content-heading" style={{ padding: '15px' }}>
                    <div>
                        { project_code }
                    </div>

                    <div className="ml-auto align-content-md-between text-muted">
                        <h6>
                            <Link to={`/projects/${this.props.match.params.projectcode}/overview/`} color="purple" style={{ paddingTop: '7px', display: 'inline-flex', paddingRight: '3px' }}><Trans i18nKey='projects.edit.BTN_SAVE_EDIT'>Overview</Trans>&nbsp;</Link>|
                            <Link to={`/projects/${this.props.match.params.projectcode}/tasks/`} color="purple" style={{ paddingTop: '7px', display: 'inline-flex', paddingRight: '3px' }}><Trans i18nKey='projects.edit.BTN_SAVE_EDIT'>Tasks</Trans>&nbsp;</Link>|
                        <Link to={`/projects/${this.props.match.params.projectcode}/kanban/`} color="yellow" style={{ paddingTop: '7px', display: 'inline-flex', paddingLeft: '3px' }}><Trans i18nKey='projects.edit.BTN_SAVE_EDIT'>Kanban</Trans>&nbsp;</Link>|
                        <Link to={`/projects/${this.props.match.params.projectcode}/calendar/`} color="yellow" style={{ paddingTop: '7px', display: 'inline-flex', paddingLeft: '3px' }}><Trans i18nKey='projects.edit.BTN_SAVE_EDIT'>Calendar</Trans>&nbsp;</Link>
                            <div className="d-none d-md-inline">|
                        <Link to={`/projects/${this.props.match.params.projectcode}/members/`} color="yellow" style={{ paddingTop: '7px', display: 'inline-flex', paddingLeft: '3px' }}><Trans i18nKey='projects.edit.BTN_SAVE_EDIT'>Members</Trans>&nbsp;</Link>|
                        <Link to={`/projects/${this.props.match.params.projectcode}/milestone/`} color="yellow" style={{ paddingTop: '7px', display: 'inline-flex', paddingLeft: '3px' }}><Trans i18nKey='projects.edit.BTN_SAVE_EDIT'>Milestones</Trans>&nbsp;</Link>|
                        <Link to={`/projects/${this.props.match.params.projectcode}/settings/`} color="yellow" style={{ paddingTop: '7px', display: 'inline-flex', paddingLeft: '3px' }}><Trans i18nKey='projects.edit.BTN_SAVE_EDIT'>Settings</Trans>&nbsp;</Link>|
                            </div>
                            <div className="float-right">
                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleProjectMenu}>
                                    <DropdownToggle color="link">
                                        <em className="fa fa-ellipsis-v fa-lg text-muted"></em>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-right-forced animated fadeInLeft">
                                        <DropdownItem onClick={() => this.setViewMode(true)}>
                                            <span><em className={"fa mr-2 fas fa-edit"}>&nbsp;</em><Trans i18nKey="teams.edit.BTN_EDIT">Settings</Trans> </span>
                                        </DropdownItem>
                                        {!project.favorite && <DropdownItem onClick={this.addAsFavorite}>
                                            <span><em className="fa mr-2 fas fa-star text-warning">&nbsp;</em><Trans i18nKey="project.edit.MENU_FAV_ADD">Add to Favorites</Trans></span>
                                        </DropdownItem>}
                                        {project.favorite && <DropdownItem onClick={this.removeAsFavorite}>
                                            <span><em className="fa mr-2 fas fa-star text-muted">&nbsp;</em><Trans i18nKey="project.edit.MENU_FAV_REMOVE">Remove from Favorites</Trans></span>
                                        </DropdownItem>}
                                        <DropdownItem onClick={this.confirmDeleteProject}>
                                            <span><em className="fa mr-2 fas fa-trash-alt text-danger">&nbsp;</em><Trans i18nKey="project.edit.MENU_DELETE_TEAM">Overview</Trans></span>
                                        </DropdownItem>
                                        <DropdownItem onClick={this.confirmDeleteProject}>
                                            <span><em className="fa mr-2 fas fa-trash-alt text-danger">&nbsp;</em><Trans i18nKey="project.edit.MENU_DELETE_TEAM">Kanban</Trans></span>
                                        </DropdownItem>
                                        <DropdownItem onClick={this.confirmDeleteProject}>
                                            <span><em className="fa mr-2 fas fa-trash-alt text-danger">&nbsp;</em><Trans i18nKey="project.edit.MENU_DELETE_TEAM">Calendar</Trans></span>
                                        </DropdownItem>
                                        <DropdownItem onClick={this.confirmDeleteProject}>
                                            <span><em className="fa mr-2 fas fa-trash-alt text-danger">&nbsp;</em><Trans i18nKey="project.edit.MENU_DELETE_TEAM">Members</Trans></span>
                                        </DropdownItem>
                                        <DropdownItem onClick={this.confirmDeleteProject}>
                                            <span><em className="fa mr-2 fas fa-trash-alt text-danger">&nbsp;</em><Trans i18nKey="project.edit.MENU_DELETE_TEAM">Milestones</Trans></span>
                                        </DropdownItem>
                                        <DropdownItem onClick={this.confirmDeleteProject}>
                                            <span><em className="fa mr-2 fas fa-trash-alt text-danger">&nbsp;</em><Trans i18nKey="project.edit.MENU_DELETE_TEAM">Delete Project</Trans></span>
                                        </DropdownItem>

                                    </DropdownMenu>
                                </Dropdown>

                            </div>
                        </h6>
                    </div>

                </div>


                <Row>
                    <Col xl="12">
                        <Switch location={this.props.match.location}>
                            <Route path={`/projects/:${project_code}/kanban/`} component={ProjectCalenbanView} />
                            <Route path={`/projects/:${project_code}/`} component={ProjectOverviewView} />
                            <Redirect to={`/projects/:${project_code}/`} />
                        </Switch>
                    </Col>
                </Row>

            </ContentWrapper>
        );
    }

}

function mapUserAvailablesToSelect(usersAvailalble) {

    return usersAvailalble.map((user) => {
        return { value: user.user_key, label: user.full_name };
    })
}
// values: Values is an object that contains all values that user has
// enter into the form.
const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    // Validate the inputs form 'values'

    if (!values.name) {
        errors.name = t("teams.edit.VALIDATE_FORM_ENTER_NAME");
    }

    if (!values.identifier) {
        errors.identifier = t("teams.edit.VALIDATE_FORM_ENTER_CODE");
    } else if (values.identifier.length < 3) {
        errors.identifier = t("teams.edit.VALIDATE_FORM_CODE_MIN_LENGTH");
    }
    // if errors is empty, the form is fine to submit (no errors)
    // If error has any properties, redux form assumes form is invalid
    return errors;
};

ProjectEdit = reduxForm({
    validate: validate,
    form: 'ProjectEditForm', // form name
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(ProjectEdit);

const selector = formValueSelector('ProjectEditForm');

function mapStateToProps(state, ownProps) {

    const p = state.projectsReducer.projectsList.find(function (element) {
        return element.public_key == ownProps.match.params.projectcode;
    });

    const uav = mapUserAvailablesToSelect(state.projectsReducer.usersAvailableForProject);
    const formMeta = getFormMeta("ProjectEditForm")(state)
    const projectMembers = p == undefined ? [] : p.members.map(m => {
        return { value: m.user_key, label: m.display_user_name };
    });

    return {
        userProfileSettings: state.sessionUserSettings,
        projectsReducer: state.projectsReducer,
        project: p == undefined ? null : p,
        formMeta,
        debugTestList: state.projectsReducer.projectsList,
        projectMembers: projectMembers,
        usersAvailableForProject: uav,
        initialValues: {
            name: p == undefined ? null : p.name,
            identifier: p == undefined ? null : p.identifier,

            description: p == undefined ? null : p.description,
            members: projectMembers
        }
    }
}

ProjectEdit = compose(
    connect(
        mapStateToProps,
        {
            requestProjects, retrieveUsers, updateProject, deleteProject, requestUserMenuItems, addProjectAsFavorite, removeProjectAsFavorite
        }
    ),
    withNamespaces())
    (ProjectEdit);

export default (ProjectEdit);